import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Comment from '../input/Comment.component';
import {PopUp, VisibleToEverybody} from './PopUp.component';
import * as Api from 'utils/api/api';
import {TASK_ACTIONS} from 'utils/constants';
import ErrorDialog from './ErrorDialog.component';
import {replacePlaceholderInMessage, getTaskUserDescription} from 'utils/valueFormatter.function';
import translate from '../translations/translations.wrapper.jsx';

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

/**
 * popup which will be used in case user finished reviewing the task
 * expects as props:
 * list of selected tasks (should be just one in the collection)
 * close popup callback
 * translate function
 */
export class TaskReviewedPopup extends PopUp {
    constructor(props) {
        super(props);
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);
        this.focusFirst = this.focusFirst.bind(this);
        this.state = {
            comment: '',
            actionRunning: false,
            displayError: null
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.focusFirst();
    }

    focusFirst() {
        this.refs.commentInput.focus();
    }

    ok() {
        const self = this;

        if (this.state.comment) {
            this.setState({actionRunning: true});

            const key = this.props.tasks[0].key;
            Api.reviewTask(key, this.state.comment).then(function (response) {
                    const withPlaceholder = replacePlaceholderInMessage(response.message, getTaskUserDescription(self.props.tasks[0]));
                    self.props.showNotification(withPlaceholder);
                    self.props.handledTaskCallback(key, self.props.navigate);
                    self.props.closeCallback();
                },
                function (error) {
                    self.setState({displayError: error});
                });
        }
    }

    buildContent() {

        const okEnabled = this.state.comment && !this.state.actionRunning;
        return (
            <div>
                {this.state.displayError && <ErrorDialog
                    error={this.state.displayError}
                    closeCallback={() => this.setState({actionRunning: false, displayError: null})}
                    translate={this.props.translate}
                />}
                <div className="modal-body ast_task_review">
                    <label htmlFor="comment"><span
                        className="text-danger">* </span> {this.props.translate("popUp.review.commentLabel")}
                    </label>
                    <VisibleToEverybody translate={this.props.translate}/>
                    <Comment ref="commentInput"
                             id="comment"
                             inputHint={this.props.translate("popUp.review.commentHint")}
                             translate={this.props.translate}
                             tabIndex={TAB_INDEX}
                             propagateValue={value => this.setState({comment: value})}
                             value={this.state.comment}/>
                </div>
                <div className="modal-footer">
                    {this.props.isCurrentTaskHandled ?
                        <div className="float-right">
                            <button
                                className="btn btn-primary btn-margin-right "
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX + 6}>{this.props.translate("popUp.reload")}
                            </button>
                        </div> :
                        <div className="float-right">
                            <button className="btn btn-primary btn-margin-right"
                                    onClick={this.ok}
                                    tabIndex={TAB_INDEX + 1}
                                    disabled={!okEnabled}
                            >{this.props.translate("popUp.ok")}</button>
                            <button className="btn btn-default"
                                    tabIndex={TAB_INDEX + 2}
                                    onBlur={this.focusFirst}
                                    onClick={this.props.closeCallback}>{this.props.translate("popUp.cancel")}</button>
                        </div>
                    }
                </div>
            </div>

        );
    }

    getType() {
        return TASK_ACTIONS.REVIEW;
    }

}

TaskReviewedPopup.propTypes = {
    tasks: PropTypes.array, // selected tasks
    closeCallback: PropTypes.func, //callback for closing the popup
    translate: PropTypes.func, // to translate texts
    handledTaskCallback: PropTypes.func
}
const withTranslations = translate(TaskReviewedPopup);
export default withTranslations;