import React, {Component, Fragment} from 'react';
import * as Api from '../../utils/api/api.js';
import {Collapse} from 'react-bootstrap';
import {HistoryAdvancedSearch} from './HistoryAdvancedSearch.component';
import {v4 as uuid} from 'uuid';
import * as resizable from 'reactabular-resizable';
import * as _ from 'lodash';
import {
    PROCESS_TYPE,
    WORKFLOW_PROCESS_STATUS,
    MY_HISTORY_URL_PART,
    TABLE_COLUMNS_TYPES
} from "utils/constants";
import {connect} from 'react-redux';
import 'reactabular-resizable/style.css';
import * as reduxSelectors from '../store/application.reducers';
import {
    toggleAdvancedSearch,
    getCostUnits,
    sortingColumnChanged,
    filterChanged
} from './myHistory.action';
import {ResizableTable} from '../resizableTable/Table.component';
import {COLUMN_MAP_ACTIVE, COLUMN_MAP_COMPLETED} from '../resizableTable/columnsMap';
import {userHasAccessRights} from "utils/accessRights.function";
import history from '../router/history.jsx';
import translate from '../translations/translations.wrapper.jsx';
import Confirm from "components/popup/ConfirmActionPopup.component";
import { launchDarkly } from "utils/launchDarkly";
import AdvancedSearchFiltering from "components/myhistory/AdvancedSearchFiltering.component";
import {withRouter} from "components/router/withRouter.tsx";

const Layout = class HistoryLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAdvancedSearch: false,
            showClearAdvancedSearchPopup: false,
            companyDocumentTypes: [],
            processStates: []
        };
        this.useAdvancedSearchStyleImprovements = launchDarkly.userClient && launchDarkly.userClient.variation("fe-advanced-search-style-improvements", false);
        this.requests = [];
        this.tableHeader = null;
        this.tableBody = null;
        this.onCostUnitSearch = this.onCostUnitSearch.bind(this);
        this.loadFiltersData = this.loadFiltersData.bind(this);
        this.onRow = this.onRow.bind(this);
        this.getTaskWithTranslation = this.getTaskWithTranslation.bind(this);
        this.confirmClearAdvancedSearch = this.confirmClearAdvancedSearch.bind(this);
        this.showClearAdvancedSearchPopup = this.showClearAdvancedSearchPopup.bind(this);
        this.hideClearAdvancedSearchPopup = this.hideClearAdvancedSearchPopup.bind(this);

        this.resizableHelper = resizable.helper({
            globalId: uuid(),
            getId: ({property}) => property
        });
    }

    componentDidMount() {
        this.loadFiltersData();
    }

    /*
     * we need to activate/deactivate the advanced filter at company context changes
     * */
    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.loadFiltersData();
        }
    }

    componentWillUnmount() {
        this.requests.forEach(p => p.cancel());
        delete this.resizableHelper;
    }

    loadFiltersData() {
        if (!userHasAccessRights(this.props.userRoles, this.props.location)) {
            return;
        }

        this.requests.push(Api.showHistorySearch().then(function (response) {
            this.setState({
                showAdvancedSearch: response
            });
            if (response) {
                this.props.getCostUnits(this.props.filters);
            }
        }.bind(this)));

        this.requests.push(Api.getCompanyDocumentTypes(this.props.currentContextId).then(function (response) {
            this.setState({
                companyDocumentTypes: response
            });
        }.bind(this)));

        this.requests.push(Api.getProcessStates().then(function (response) {
            this.setState({
                processStates: response
            });
        }.bind(this)));
    }

    onCostUnitSearch(value, selectedValue) {
        let query = {
            dimension: value,
            number: selectedValue ? selectedValue.value : undefined,
            label: selectedValue ? selectedValue.label : undefined
        };

        this.props.onCostUnitSearch(query);
    }

    onSelectRow(key) {
        this.props.history(MY_HISTORY_URL_PART + key);
    }

    //bind the row click to be able to navigate to each history task detail
    onRow(task) {
        return {
            onClick: () => this.onSelectRow(task.key),
            onKeyDown: () => this.onSelectRow(task.key)
        }
    }

    //needed to translate the document type
    getTaskWithTranslation(tasks) {
        if (tasks) {
            let translatedTasks = _.cloneDeep(tasks);
            return translatedTasks.map(task => {
                task.status = this.props.translate("workflowProcessStatus." + WORKFLOW_PROCESS_STATUS.asString(task.status));
                return task;
            });
        }
        return [];
    }

    confirmClearAdvancedSearch() {
        this.props.onAdvancedSearchFiltersClear();
        this.hideClearAdvancedSearchPopup();
    }

    showClearAdvancedSearchPopup() {
        this.setState({showClearAdvancedSearchPopup: true});
    }

    hideClearAdvancedSearchPopup() {
        this.setState({showClearAdvancedSearchPopup: false});
    }

    render() {
        let {processType, translate, filterProcess, advancedSearchOpen} = this.props;
        let {showAdvancedSearch} = this.state;

        let mappedColumns = (processType === PROCESS_TYPE.COMPLETED) ? COLUMN_MAP_COMPLETED : COLUMN_MAP_ACTIVE;
        let selector_values = Object.assign({}, {"documentType": this.state.companyDocumentTypes});
        selector_values[TABLE_COLUMNS_TYPES.STATUS] = this.state.processStates;
        let mappedTasks = Object.assign({}, this.props.tasks);
        mappedTasks.rows = this.getTaskWithTranslation(mappedTasks.allTasks);
        delete mappedTasks.allTasks;

        //map cost units to current filters
        let costValue = this.props.costUnitsOptions ? this.props.costUnitsOptions.map(unit => {
            unit.selectedValue = undefined;
            this.props.filters.costUnits.forEach(value => {
                if ((value.dimension === unit.dimension)) {
                    unit.selectedValue = value.number;
                    unit.label = value.label;
                }
            });
            return unit;
        }) : [];

        //map acount value
        let account = this.props.filters && this.props.filters.account ? {
            selectedValue: this.props.filters.account.number,
            label: this.props.filters.account.label
        } : {};

        return (
                this.useAdvancedSearchStyleImprovements ?
                    <div>

                        {this.state.showClearAdvancedSearchPopup &&
                            <Confirm translate={this.props.translate}
                                     title={this.props.translate("popUp.clearAdvancedSearch.title")}
                                     message={this.props.translate("popUp.clearAdvancedSearch.message")}
                                     confirmButtonText={this.props.translate("popUp.clear")}
                                     confirmButtonColor={'btn-default'}
                                     handleAction={this.confirmClearAdvancedSearch}
                                     closeCallback={this.hideClearAdvancedSearchPopup}
                            />}

                        <section role="complementary" aria-labelledby="myHistoryGrid">
                                <div className="btn-toolbar btn-toolbar-primary btn-toolbar-advanced mb-0">
                                    <div className="btn-group float-left" role="group">
                                        <button type="button"
                                                onClick={filterProcess.bind(this, PROCESS_TYPE.ACTIVE)}
                                                className={"btn btn-default " + (processType === PROCESS_TYPE.ACTIVE ? "active" : "")}>
                                            {translate("myHistory.activeProcesses")}
                                        </button>

                                        <button type="button"
                                                onClick={filterProcess.bind(this, PROCESS_TYPE.COMPLETED)}
                                                className={"btn btn-default " + (processType === PROCESS_TYPE.COMPLETED ? "active" : "")}>
                                            {translate("myHistory.completedProcesses")}
                                        </button>
                                    </div>
                                    {showAdvancedSearch &&
                                        <div role="presentation" className="advanced-search"
                                             onClick={this.props.toggleAdvancedSearch.bind(null, !this.props.advancedSearchOpen)}>
                                            <span className="search-title">{translate("myHistory.advancedSearch")}</span>
                                            {this.props.noOfAdvancedSearchFiltersActive() > 0 &&
                                                <span
                                                    className="margin-left-8 label label-info">{this.props.noOfAdvancedSearchFiltersActive()}</span>
                                            }
                                            <span
                                                className={"margin-left-8 appicon sort-arrow sort-arrow-" + (advancedSearchOpen ? "down" : "right")} />
                                        </div>
                                    }
                                </div>

                                {showAdvancedSearch && advancedSearchOpen &&
                                    <Collapse in={advancedSearchOpen}>
                                                <AdvancedSearchFiltering costUnits={costValue}
                                                                       account={account}
                                                                       isClearActive={this.props.advancedSearchFiltersActive()}
                                                                       onSearch={this.onCostUnitSearch}
                                                                       onAccountSearch={this.props.onAccountSearch}
                                                                       onClearFilters={this.showClearAdvancedSearchPopup}
                                                                       translate={translate} />
                                    </Collapse>
                                }
                            </section>
                            <main>
                                <div id="myHistoryGrid"
                                     className={showAdvancedSearch && advancedSearchOpen ? "hasAdvancedSearchStyleImprovements" : ""}>

                                    <ResizableTable tasks={mappedTasks}
                                                    className={showAdvancedSearch && advancedSearchOpen ? "hasAdvancedSearchStyleImprovements rows_" + Math.ceil((costValue.length + 1) / 4) : ""}
                                                    defaultColumns={mappedColumns}
                                                    filterChanged={this.props.filterChanged}
                                                    translate={this.props.translate}
                                                    filters={this.props.filters}
                                                    selectors={selector_values}
                                                    loadingStatus={this.props.loadingStatus}
                                                    loadMoreTasks={this.props.onBodyScroll}
                                                    onRow={this.onRow.bind(this)}
                                                    refreshGrid={this.props.refreshGrid}
                                                    saveScrollPosition={this.props.saveScrollPosition}
                                                    scrollTop={this.props.scrollTop}
                                    />
                                </div>
                            </main>
                        </div>:
                    <div>
                            <section role="complementary" aria-labelledby="myHistoryGrid">
                                <div className="btn-toolbar btn-toolbar-primary btn-toolbar-advanced mb-0">
                                    <div>
                                        <div className="btn-group float-left" role="group">
                                            <button type="button"
                                                    onClick={filterProcess.bind(this, PROCESS_TYPE.ACTIVE)}
                                                    className={"btn btn-default " + (processType === PROCESS_TYPE.ACTIVE ? "active" : "")}>
                                                {translate("myHistory.activeProcesses")}
                                            </button>

                                            <button type="button"
                                                    onClick={filterProcess.bind(this, PROCESS_TYPE.COMPLETED)}
                                                    className={"btn btn-default " + (processType === PROCESS_TYPE.COMPLETED ? "active" : "")}>
                                                {translate("myHistory.completedProcesses")}
                                            </button>
                                        </div>
                                        {showAdvancedSearch &&
                                            <div role="presentation" className="advanced-search"
                                                 onClick={this.props.toggleAdvancedSearch.bind(null, !this.props.advancedSearchOpen)}>
                                                <span className="search-title">{translate("myHistory.advancedSearch")}</span>
                                                <span
                                                    className={"margin-left-8 appicon sort-arrow sort-arrow-" + (advancedSearchOpen ? "down" : "right")} />
                                            </div>
                                        }
                                    </div>
                                    {showAdvancedSearch && this.props.advancedSearchFiltersActive() &&
                                        <div className="advanced-search-clear"
                                             onClick={this.props.onAdvancedSearchFiltersClear}>
                                            <span className="sort-arrow clear-icon" />
                                            <span className="clear-title">{translate("myHistory.clearAllFilters")}</span>
                                        </div>
                                    }
                                </div>

                                {showAdvancedSearch && advancedSearchOpen &&
                                    <Collapse in={advancedSearchOpen}>
                                        <div className="panel-body advanced-search-container px-0 transparent-background">
                                            <HistoryAdvancedSearch costUnits={costValue}
                                                                   account={account}
                                                                   onSearch={this.onCostUnitSearch}
                                                                   onAccountSearch={this.props.onAccountSearch}
                                                                   translate={translate} />
                                        </div>
                                    </Collapse>
                                }
                            </section>
                            <main>
                            <div id="myHistoryGrid"
                            className={showAdvancedSearch && advancedSearchOpen ? "hasAdvanced" : ""}>

                            <ResizableTable tasks={mappedTasks}
                                            className={showAdvancedSearch && advancedSearchOpen ? "hasAdvanced rows_" + Math.ceil((costValue.length + 1) / 4) : ""}
                                            defaultColumns={mappedColumns}
                                            filterChanged={this.props.filterChanged}
                                            translate={this.props.translate}
                                            filters={this.props.filters}
                                            selectors={selector_values}
                                            loadingStatus={this.props.loadingStatus}
                                            loadMoreTasks={this.props.onBodyScroll}
                                            onRow={this.onRow.bind(this)}
                                            refreshGrid={this.props.refreshGrid}
                                            saveScrollPosition={this.props.saveScrollPosition}
                                            scrollTop={this.props.scrollTop}
                            />
                        </div>
                </main>
                </div>

        )
    }
};


const withTranslations = translate(Layout);
const historyLayoutWithLocation = withRouter(withTranslations);
const mapStateToProps = function(store) {
    return {
        advancedSearchOpen: reduxSelectors.getToggleAdvancedSearch(store),
        loadingStatus: reduxSelectors.getHistoryLoadingStatus(store),
        processType: reduxSelectors.getHistoryProcessFilter(store),
        tasks: reduxSelectors.getHistoryTasksForRender(store),
        filters: reduxSelectors.getHistoryFilter(store),
        costUnitsOptions: reduxSelectors.getCostUnitsOptions(store),
        sortingLogic: reduxSelectors.getHistorySortColumn(store),
        scrollTop: reduxSelectors.getHistoryScrollTop(store),
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
    };
};
const connected = connect(mapStateToProps, {
    toggleAdvancedSearch,
    getCostUnits,
    sortingColumnChanged,
    filterChanged
})(historyLayoutWithLocation);

export default connected;
