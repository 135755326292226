import BypassCIRContainer from "./BypassCIRContainer.component";
import './attachments.scss';
import {AttachmentsPanelProps} from "types/attachment";
import { useSelector } from "react-redux";
import { isDocumentPreviewDetached } from "components/store/application.reducers";

const AttachmentsPanel = (props: AttachmentsPanelProps) => {
    const isPreviewDetached = useSelector(isDocumentPreviewDetached);

    return (
        <div
            className={`p-0 attachments-preview-modal grid-attachments-preview ${isPreviewDetached ? "detached-height" : ""}`}
            style={{width: props.width + "px"}}
            data-cy="taskDetail.attachmentsPanel">
                <BypassCIRContainer data-testid={"bypass-cir-container"}
                                    isAttachmentPanelWidthDefault={props.isAttachmentPanelWidthDefault}
                                    isAttachmentPanelMaxWidth={props.isAttachmentPanelMaxWidth}
                                    maximizePanel={props.maximizePanel}
                                    minimizePanel={props.minimizePanel}
                                    ownerDocument={props.ownerDocument}/>

        </div>
    );
}

export default AttachmentsPanel;