import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Popup from './PopUp.component';
import Comment from '../input/Comment.component';
import EmailInput from '../input/EmailInput.component';
import ErrorDialog from './ErrorDialog.component';
import * as Api from '../../utils/api/api.js';
import translate from '../translations/translations.wrapper.jsx';
import {TASK_ACTIONS} from '../../utils/constants';
import './emailPopup.scss';

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

export class Email extends Popup {
    constructor(props) {
        super(props);
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);
        this.focusFirst = this.focusFirst.bind(this);

        const firstTask = props.tasks[0];
        let fromField = " " + this.props.translate("popUp.email.from") + " " + (firstTask.supplierName ? firstTask.supplierName : firstTask.requesterName);
        //let subject = this.props.translate("documentType." + DOCUMENT_TYPE.asString(firstTask.documentType)) + " - " + (firstTask.displayId ? firstTask.displayId : "") + (fromField ? fromField : "");
        let subject = fromField ? fromField + " : " + firstTask.description : firstTask.description;

        this.state = {
            to: '',
            cc: '',
            bcc: '',
            subject: subject,
            sendAttachment: true,
            sendCopy: true,
            comment: '',
            actionRunning: false,
            displayError: null,
            showCcInput: false,
            showBccInput: false
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.focusFirst();
    }

    focusFirst() {
        this.refs.toInput.focus();
    }

    ok() {
        if (this.state.to && this.state.comment) {
            this.setState({actionRunning: true});

            const key = this.props.tasks[0].key;
            const self = this;

            let toCall = this.props.sendDocument ? Api.emailDocument : Api.emailTask;

            toCall(key,
                this.state.to, this.state.comment, this.state.subject,
                this.state.sendAttachment, this.state.sendCopy, this.state.cc, this.state.bcc,
                this.props.companyId
            ).then(function (response) {
                    self.props.showNotification(response.message);
                    self.props.closeCallback();
                },
                function (error) {
                    self.setState({displayError: error});
                }
            );

        }
    }

    buildContent() {

        const okEnabled = !this.state.actionRunning && this.state.to && this.state.comment;
        const ccButtonsVisible = !this.state.showCcInput || !this.state.showBccInput;
        let toEmailClass = "mb-3 p-0 position-relative " + (ccButtonsVisible ? "col-md-9" : "col-md-12");
        let myEmail = this.props.loggedInData ? this.props.loggedInData.emailAddress : "";
        return (
            <div>
                {this.state.displayError && <ErrorDialog
                    error={this.state.displayError}
                    closeCallback={() => this.setState({actionRunning: false, displayError: null})}
                    translate={this.props.translate}
                />}
                <div className="panel-body email-dialog">
                    <div className="alert alerm-sm alert-info py-4">{this.props.translate("popUp.email.placeholder")}</div>

                    <label htmlFor="to"><span
                        className="text-danger">* </span>{this.props.translate("popUp.email.to")}</label>

                    <div className="row w-100 m-0 p-0">
                        <EmailInput
                            ref="toInput"
                            className={toEmailClass} id="to" thisId="to"
                            tabIndex={TAB_INDEX}
                            propagateValue={(value) => this.setState({to: value})}
                            translate={this.props.translate}
                            value={this.state.to}
                        />
                        {ccButtonsVisible &&
                            <div className="col-md-3 p-0 text-end">
                                {!this.state.showCcInput &&

                                    <button type="button"
                                            className="btn btn-default btn-cc "
                                            tabIndex={TAB_INDEX + 1}
                                            onClick={() => {
                                                this.setState({showCcInput: true})
                                            }}>
                                        {this.props.translate("popUp.email.cc")}
                                    </button>

                                }
                                {!this.state.showBccInput &&

                                    <button type="button"
                                            className="btn btn-default btn-cc"
                                            tabIndex={TAB_INDEX + 2}
                                            onClick={() => {
                                                this.setState({showBccInput: true})
                                            }}>
                                        {this.props.translate("popUp.email.bcc")}
                                    </button>

                                }
                            </div>
                        }

                    </div>

                    {this.state.showCcInput &&
                        <div>
                            <label htmlFor="cc">{this.props.translate("popUp.email.cc")}</label>
                            <EmailInput className="w-100 mb-3 p-0 position-relative" id="cc" thisId="cc"
                                        propagateValue={(value) => this.setState({cc: value})}
                                        value={this.state.cc}
                                        translate={this.props.translate}
                                        tabIndex={TAB_INDEX + 3}
                            />
                        </div>
                    }

                    {this.state.showBccInput &&
                        <div>
                            <label htmlFor="bcc">{this.props.translate("popUp.email.bcc")}</label>
                            <EmailInput className="w-100 mb-3 p-0 position-relative" id="bcc" thisId="bcc"
                                        propagateValue={(value) => this.setState({bcc: value})}
                                        value={this.state.bcc}
                                        translate={this.props.translate}
                                        tabIndex={TAB_INDEX + 4}/>
                        </div>
                    }


                    <label htmlFor="subject"><span
                        className="text-danger">* </span>{this.props.translate("popUp.email.subject")}</label>
                    <input type="text" className="w-100 mb-3 px-4 position-relative" value={this.state.subject}
                           id="subject"
                           placeholder={this.props.translate("popUp.email.subjectPlaceholder")}
                           onChange={e => this.setState({subject: e.target.value})} tabIndex="4"/>

                    <label htmlFor="comment"><span
                        className="text-danger">* </span> {this.props.translate("popUp.email.commentLabel")}</label>

                    <Comment
                        inputHint={this.props.translate("popUp.email.messagePlaceholder")}
                        inputLimit={0} // without limit
                        translate={this.props.translate}
                        tabIndex={TAB_INDEX + 5}
                        id="comment"
                        value={this.state.comment}
                        propagateValue={value => this.setState({comment: value})}
                    />

                    <div className="checkbox my-2">
                        <input type="checkbox"
                               id="attachDoc"
                               onChange={event => this.setState({sendAttachment: event.target.checked})}
                               tabIndex={TAB_INDEX + 6}
                               checked={this.state.sendAttachment}/>
                        <label className="form-check-label" htmlFor="attachDoc">
                            {this.props.translate("popUp.email.attachDocument")}
                        </label>
                    </div>
                    <br/>

                    <div className="checkbox my-2">
                        <input type="checkbox"
                               id="sendCopy"
                               onChange={event => this.setState({sendCopy: event.target.checked})}
                               tabIndex={TAB_INDEX + 7}
                               checked={this.state.sendCopy}/>
                        <label className="form-check-label" htmlFor="sendCopy">
                            {this.props.translate("popUp.email.sendMeCopy", myEmail)}
                        </label>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="float-right">
                        <button
                            className="btn btn-primary btn-margin-right "
                            onClick={this.ok}
                            disabled={!okEnabled}
                            tabIndex={TAB_INDEX + 6}> {this.props.translate("popUp.email.send")}</button>
                        <button className="btn btn-default"
                                onClick={this.props.closeCallback}
                                onBlur={this.focusFirst}
                                tabIndex={TAB_INDEX + 7}> {this.props.translate("popUp.cancel")}</button>
                    </div>
                </div>
            </div>
        );
    }

    getType() {
        return TASK_ACTIONS.EMAIL;
    }
}

const withTranslations = translate(Email);
export default withTranslations;

Email.propTypes = {
    tasks: PropTypes.array, // selected tasks
    closeCallback: PropTypes.func, //callback for closing the popup
    translate: PropTypes.func, // to translate texts
    showNotification: PropTypes.func, // to show transient notification
};