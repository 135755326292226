import {
    CHANGE_SELECTED_DOCUMENT,
    TASK_DETAILS_LOADED,
    TASK_DETAILS_LOADING,
    HAS_EDITED_DOCUMENT,
    CHANGE_ROTATION,
    UPDATE_DOCUMENT_VERSION,
    WORKFLOW_DETAILS_LOADED,
    WORKFLOW_HISTORY_LOADED,
    WORKFLOW_DETAILS_LOADING_ERROR,
    TASK_DOCUMENT_DATA_LOADED,
    CHANGE_ZOOM_PERCENTAGE,
    WORKFLOW_DETAILS_LOADING,
    TASK_DOCUMENT_DATA_LOADING,
    TASK_COMMENTS_LOADED,
    TASK_COMMENTS_LOADING,
    CHANGE_IMAGE_ZOOM_PERCENTAGE,
    CACHE_ATTACHMENTS_IMAGES_URLS,
    CHANGE_UNSAVED_COMMENT,
    IFRAME_HAS_CHANGES,
    COMMENTS_HAVE_CHANGES,
    IFRAME_HAS_VBNXT_SAVE_DISCARD_FLAG,
    TASK_COMMENT_SAVING,
    TASK_COMMENT_SAVED,
    TASK_COMMENT_SAVING_ERROR,
    TASK_COMMENT_START_SAVING,
    DOCUMENT_PREVIEW_DETACH,
    DOCUMENT_PREVIEW_REATTACH,
    DOCUMENT_PREVIEW_STATUS_CHANGED,
    DOCUMENT_PREVIEW_TITLE_CHANGED,
    TASK_DETAILS_CLOSING,
} from "../store/actionTypes";

import {
    LOADING_STATUS,
    ZOOM_DIMENSIONS,
    APPROVAL,
    SIZE_PATTERN,
    PAGE_PATTERN,
    OK_CIR_STATUS,
    IMAGE_MIME_TYPE, COMMENT_SAVING_STATUS, COMPLETED_CIR_STATUS, DOCUMENT_PREVIEW_STATUS,
} from "utils/constants";
import _ from "lodash";
import {createSelector} from 'reselect';
import MIMEType from "whatwg-mimetype";


export const DEFAULT_STATE = {
    zoomPercentage: 100,
    imageZoomPercentage: 100,
    details: {},
    attachments: null,
    documentPreviewDetach: {
        title: null,
        isDetached: false,
        status: DOCUMENT_PREVIEW_STATUS.WAITING,

    },
    loadingStatus: undefined,
    selectedDocument: {
        index: 0
    },
    editedDocument: false,
    rotation: 0,
    workflow: {
        details: null,
        history: null,
        loading: null
    },
    documentData: null,
    documentDataLoadingStatus: null,
    comments: {
        data: [],
        currentComment: "",
        unsavedComment: "",
        loading: null,
        haveUnsavedChanges: false,
        savingCommentStatus: null
    },
    integrationIframe: {
        hasUnsavedChanges: false,
        hasVBNXTSaveDiscardFlag: false
    }
};

export default function myTaskDetailsReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {

        case CHANGE_ZOOM_PERCENTAGE:
            partialState = _.cloneDeep(state);
            partialState.zoomPercentage = action.data;
            return Object.assign({}, state, partialState);

        case CHANGE_IMAGE_ZOOM_PERCENTAGE:
            partialState = _.cloneDeep(state);
            partialState.imageZoomPercentage = action.data;
            return Object.assign({}, state, partialState);

        case TASK_DETAILS_LOADING:
            partialState = _.cloneDeep(state);
            partialState.loadingStatus = LOADING_STATUS.LOADING;
            partialState.attachments = null;
            partialState.selectedDocument = {index: 0};
            partialState.integrationIframe = _.cloneDeep(DEFAULT_STATE.integrationIframe);
            partialState.comments.haveUnsavedChanges = false;
            partialState.comments.unsavedComment = "";
            return Object.assign({}, state, partialState);

        case TASK_DETAILS_LOADED:
            partialState = _.cloneDeep(state);
            //reset the flag for any new task loaded
            if (partialState.details.key !== action.data.taskDetails?.key)
                partialState.editedDocument = false;
            partialState.attachments = action.data.attachments;
            partialState.loadingStatus = LOADING_STATUS.DONE;
            partialState.details = action.data.taskDetails;
            partialState.rotation = 0;
            return Object.assign({}, state, partialState);

        case TASK_DETAILS_CLOSING:
            partialState = _.cloneDeep(state);
            partialState.attachments = null;
            partialState.selectedDocument = {index: 0};
            partialState.integrationIframe = _.cloneDeep(DEFAULT_STATE.integrationIframe);
            partialState.comments.haveUnsavedChanges = false;
            partialState.comments.unsavedComment = "";
            return Object.assign({}, state, partialState);

        case WORKFLOW_DETAILS_LOADING:
            partialState = _.cloneDeep(state);
            partialState.workflow.loading = true;
            return Object.assign({}, state, partialState);

        case WORKFLOW_DETAILS_LOADED:
            partialState = _.cloneDeep(state);
            partialState.workflow.details = action.data;
            partialState.workflow.loading = false;
            return Object.assign({}, state, partialState);

        case WORKFLOW_HISTORY_LOADED:
            partialState = _.cloneDeep(state);
            partialState.workflow.history = action.data;
            return Object.assign({}, state, partialState);

        case WORKFLOW_DETAILS_LOADING_ERROR:
            partialState = _.cloneDeep(state);
            partialState.workflow.details = {};
            partialState.workflow.history = {};
            partialState.workflow.loading = false;
            return Object.assign({}, state, partialState);

        case CHANGE_SELECTED_DOCUMENT:
            let selectedObj = {};
            partialState = _.cloneDeep(state);
            selectedObj = action.data;
            selectedObj.index = action.index;
            partialState.selectedDocument = selectedObj;
            partialState.imageZoomPercentage = 100;
            return Object.assign({}, state, partialState);

        case HAS_EDITED_DOCUMENT:
            partialState = _.cloneDeep(state);
            partialState.editedDocument = action.data;
            return Object.assign({}, state, partialState);

        case CHANGE_ROTATION:
            partialState = _.cloneDeep(state);
            partialState.rotation = action.data;
            return Object.assign({}, state, partialState);

        case UPDATE_DOCUMENT_VERSION:
            partialState = _.cloneDeep(state);
            partialState.details.documentVersion = action.data;
            return Object.assign({}, state, partialState);

        case TASK_DOCUMENT_DATA_LOADING:
            partialState = _.cloneDeep(state);
            partialState.documentDataLoadingStatus = true;
            return Object.assign({}, state, partialState);

        case TASK_DOCUMENT_DATA_LOADED:
            partialState = _.cloneDeep(state);
            partialState.documentData = action.data;
            partialState.documentDataLoadingStatus = false;
            return Object.assign({}, state, partialState);

        case CHANGE_UNSAVED_COMMENT:
            partialState = _.cloneDeep(state);
            partialState.comments.unsavedComment = action.data;
            partialState.comments.haveUnsavedChanges = (action.data !== null && action.data !== "");
            return Object.assign({}, state, partialState);

        case TASK_COMMENT_START_SAVING:
            partialState = _.cloneDeep(state);
            partialState.comments.savingCommentStatus = COMMENT_SAVING_STATUS.START;
            return Object.assign({}, state, partialState);

        case TASK_COMMENT_SAVING:
            partialState = _.cloneDeep(state);
            partialState.comments.savingCommentStatus = COMMENT_SAVING_STATUS.SAVING;
            return Object.assign({}, state, partialState);

        case TASK_COMMENT_SAVED:
            partialState = _.cloneDeep(state);
            partialState.comments.savingCommentStatus = COMMENT_SAVING_STATUS.DONE;
            partialState.comments.unsavedComment = null;
            partialState.comments.haveUnsavedChanges = false;
            return Object.assign({}, state, partialState);

        case TASK_COMMENT_SAVING_ERROR:
            partialState = _.cloneDeep(state);
            partialState.comments.savingCommentStatus = COMMENT_SAVING_STATUS.ERROR;
            return Object.assign({}, state, partialState);

        case TASK_COMMENTS_LOADING:
            partialState = _.cloneDeep(state);
            partialState.comments.loading = true;
            return Object.assign({}, state, partialState);

        case TASK_COMMENTS_LOADED:
            partialState = _.cloneDeep(state);
            partialState.comments.data = action.data;
            partialState.comments.loading = false;
            let currentFound = _.find(action.data, ['current', true]);
            partialState.comments.currentComment = currentFound ? currentFound.content : "";
            return Object.assign({}, state, partialState);


        case CACHE_ATTACHMENTS_IMAGES_URLS:
            partialState = _.cloneDeep(state);
            if (partialState.attachments?.attachments) {
                let foundAttachment = _.findIndex(partialState.attachments.attachments, ['id', action.data.attachmentIndex]);

                if (!partialState.attachments.attachments[foundAttachment].dsURL)
                    partialState.attachments.attachments[foundAttachment].dsURL = {};
                partialState.attachments.attachments[foundAttachment].dsURL[action.data.imageIndex] = action.data.imageURL;
            }
            return Object.assign({}, state, partialState);

        case DOCUMENT_PREVIEW_DETACH:
            partialState = _.cloneDeep(state);
            partialState.documentPreviewDetach.isDetached = true;
            return Object.assign({}, state, partialState);

        case DOCUMENT_PREVIEW_REATTACH:
            partialState = _.cloneDeep(state);
            partialState.documentPreviewDetach.isDetached = false;
            return Object.assign({}, state, partialState);

        case DOCUMENT_PREVIEW_TITLE_CHANGED:
            partialState = _.cloneDeep(state);
            partialState.documentPreviewDetach.title = action.data;
            return Object.assign({}, state, partialState);

        case DOCUMENT_PREVIEW_STATUS_CHANGED:
            partialState = _.cloneDeep(state);
            partialState.documentPreviewDetach.status = action.data;
            return Object.assign({}, state, partialState);

        case IFRAME_HAS_CHANGES:
            partialState = _.cloneDeep(state);
            partialState.integrationIframe.hasUnsavedChanges = action.data;
            return Object.assign({}, state, partialState);

        case COMMENTS_HAVE_CHANGES:
            partialState = _.cloneDeep(state);
            partialState.comments.haveUnsavedChanges = action.data;
            return Object.assign({}, state, partialState);

        case IFRAME_HAS_VBNXT_SAVE_DISCARD_FLAG:
            partialState = _.cloneDeep(state);
            partialState.integrationIframe.hasVBNXTSaveDiscardFlag = action.data;
            return Object.assign({}, state, partialState);

        default:
            return state;
    }
}


export const getImagesForRender = createSelector(
    [getAttachments], (attachmentsList) => {
        let imageCollection = {};

        if (!attachmentsList)
            return imageCollection;

        attachmentsList.forEach(attachment => {
            if (attachment.mimeType && attachment.mimeType.includes(IMAGE_MIME_TYPE)) {
                let images = processDSImages(attachment);
                imageCollection[attachment.id] = images;
            }
        });

        return imageCollection;

    }
);


export const getTaskAttachmentsForRender = createSelector(
    [getAttachments], (attachmentsList) => {
        let attachmentCollection = [];

        if (!attachmentsList)
            return attachmentCollection;


        attachmentCollection = attachmentsList.map(attachment => {

                const collection = {
                    id: attachment.id,
                    pageCount: attachment.pageCount,
                    status: attachment.status,
                    deleteUrlTemplate: attachment.deletepageurltemplate,
                    mimeType: attachment.mimeType ? MIMEType.parse(attachment.mimeType)?.essence : null,
                    information: attachment.information || null,
                };

                if (attachment.mimeType && attachment.mimeType.includes(IMAGE_MIME_TYPE)) {
                    collection.images = processImages(attachment);
                }
                //const key: keyof string[] = Object.keys(props.urlsOriginalJson)[0] as keyof Array<string>;
                // const originalUrl = APPROVAL + props.urlsOriginalJson[key].toString()?.replace("/approval/jaxrs/", '');;

                if (attachment.urlsOriginalJson) {
                    const key = Object.keys(attachment.urlsOriginalJson)[0];
                    collection.urlsOriginalJson = attachment.urlsOriginalJson;
                    collection.originalUrl = APPROVAL + attachment.urlsOriginalJson[key].toString()?.replace("/approval/jaxrs/", '');
                }
                return collection;
            }
        );
        return attachmentCollection;
    }
);

function processDSImages(attachment) {
    let images = [];
    _.times(attachment.pageCount, (index) => {
        let imageObj = {};
        imageObj.pageId = attachment.pageIds[index] ? attachment.pageIds[index] : index + 1;
        imageObj.dsURL = attachment.dsURL ? attachment.dsURL[imageObj.pageId] || "" : null;

        images.push(imageObj);
    });

    return images;
}

function processImages(attachment) {
    let images = [];
    _.times(attachment.pageCount, (index) => {
        let imageObj = {};
        imageObj.status = attachment.status;
        imageObj.pageId = attachment.pageIds[index] ? attachment.pageIds[index] : index + 1;
        const pageId = index + 1;

        if (attachment.status === OK_CIR_STATUS || attachment.status === COMPLETED_CIR_STATUS) {
            imageObj.urltemplate = attachment.urltemplate;
            imageObj.src = decodeURIComponent(attachment.urltemplate).replace(SIZE_PATTERN, ZOOM_DIMENSIONS[4].imageWidth + '')
                .replace(PAGE_PATTERN, pageId + '');
            imageObj.rotationSrc = decodeURIComponent(attachment.urlRotationTemplate).replace(SIZE_PATTERN, ZOOM_DIMENSIONS[4].imageWidth + '')
                .replace(PAGE_PATTERN, pageId + '');
        } else {
            imageObj.src = null;
        }

        if (attachment.urlsOriginalJson) {
            // get the DS url
            const key = Object.keys(attachment.urlsOriginalJson)[index];
            imageObj.url = APPROVAL + attachment.urlsOriginalJson[key]?.toString().replace("/approval/jaxrs/", '');
        }

        images.push(imageObj);
    });

    return images;
}

function getComments(commentsAndEvents) {
    return _.filter(commentsAndEvents, comment => comment.content !== '');
}


export function getAttachments(state) {
    return state.attachments ? state.attachments.attachments : null;
}

export function getAttachmentsDownloadLinks(state) {
    let response = {
        downloadPDFUrl: '',
        downloadZIPUrl: '',
        attachmentService: ''
    }
    if (!state.attachments || state.attachments.length === 0)
        return response;

    //no url for encrypted files
    if (_.findIndex(state.attachments.attachments, (x => x.pageCount === 0)) !== -1) {
        response.downloadPDFUrl = null;
    } else {
        response.downloadPDFUrl = state.attachments.downloadPDFUrl;
    }

    response.attachmentService = state.attachments.attachmentCollectionValues?.attachmentService;
    response.downloadZIPUrl = state.attachments.downloadZIPUrl;

    return response;
}

export function getTaskDetails(state) {
    return state.details;
}

export function getDetails(state) {
    return state.details ? state.details : undefined;
}

export function getZoomPercentage(state) {
    return state.zoomPercentage;
}

export function getImageZoomPercentage(state) {
    return state.imageZoomPercentage;
}

export function getSelectedDocument(state) {
    return state.selectedDocument;
}

export function getDetailsLoadingStatus(state) {
    return state.loadingStatus;
}

export function getDocumentEditedFlag(state) {
    return state.editedDocument;
}

export function getDocumentRotation(state) {
    return state.rotation;
}

export function getTaskWorkflowDetails(state) {
    return state.workflow.details;
}

export function getWorkflowDetailsLoadingStatus(state) {
    return state.workflow.loading;
}

export function getTaskWorkflowHistory(state) {
    return state.workflow.history;
}

export function getTaskDocumentData(state) {
    return state.documentData;
}

export function getTaskDocumentDataLoadingStatus(state) {
    return state.documentDataLoadingStatus;
}

export function getTaskCommentsAndEvents(state) {
    return state.comments.data;
}

export function getTaskComments(state) {
    return getComments(state.comments.data);
}

export function getTaskCommentsLoadingStatus(state) {
    return state.comments.loading;
}

export function getTaskCurrentComment(state) {
    return state.comments.currentComment;
}

export function getTaskUnsavedComment(state) {
    return state.comments.unsavedComment;
}

export function getTaskCommentSavingStatus(state) {
    return state.comments.savingCommentStatus;
}

export function getTaskCommentsCount(state) {
    return getComments(state.comments.data).length;
}

export function getAllowExtraComments(state) {
    return state.details?.taskPermission?.allowExtraComments || state.details?.allowExtraComments;
}

export function getIsTaskDetailsLocked(state) {
    return state.comments.hasUnsavedChanges || state.integrationIframe.hasUnsavedChanges
}

export function getCommentsHaveUnsavedChanges(state) {
    return state.comments.haveUnsavedChanges;
}

export function getIframeHasUnsavedChanges(state) {
    return state.integrationIframe.hasUnsavedChanges;
}

export function getVBNXTSaveDiscardFlag(state) {
    return state.integrationIframe.hasVBNXTSaveDiscardFlag;
}

export function isDocumentPreviewDetached(state) {
    return state.documentPreviewDetach?.isDetached;
}

export function getDocumentPreviewTitle(state) {
    return state.documentPreviewDetach?.title;
}

export function getDocumentPreviewStatus(state) {
    return state.documentPreviewDetach?.status;
}