import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {PopUp} from './PopUp.component';
import {CONFIRM_POPUP_TYPE} from 'utils/constants';


const TAB_INDEX = 1000;

/**
 * popup which will be used in case user has selected 'approve with comment'
 * expects as props:
 * list of selected tasks
 * close popup callback
 * translate function
 */
export class Confirm extends PopUp {

    static propTypes = {
        closeCallback: PropTypes.func, //callback for closing the popup
        handleAction: PropTypes.func, //callback for closing the popup
        translate: PropTypes.func, // to translate texts
        hideClose: PropTypes.bool //do not show the close button
    };

    constructor(props) {
        super(props);
        this.buildContent = this.buildContent.bind(this);
        this.handleAction = this.handleAction.bind(this);
        this.save = this.save.bind(this);
        this.discard = this.discard.bind(this);

        this.userInput = React.createRef();

        this.discardButtonRef = React.createRef();

        this.state = {
            comment: ''
        }

        this.okButtonRef = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();
        if (this.okButtonRef.current)
            this.okButtonRef.current.focus();
        else
            this.closeButtonRef.current.focus();
    }

    handleAction() {
        if (this.props.hasUserInput)
            this.props.handleAction(this.userInput.current.value);
        else
            this.props.handleAction();
    }

    save() {
        this.props.saveCallback();
        this.props.closeCallback();
    }

    discard() {
        this.props.discardCallback();
        this.props.closeCallback();
    }

    getClasses() {
        return this.props.modalType ? `modal ${this.props.modalType}` : "";
    }


    buildContent() {
        const confirmButtonText = this.props.confirmButtonText || 'popUp.ok';
        const cancelButtonText = this.props.cancelButtonText || 'popUp.cancel';
        const confirmButtonColor = this.props.confirmButtonColor || 'btn-primary';
        const confirmIcon = this.props.confirmIcon || 'vismaicon-help';

        return (
            <div>
                <div className="modal-body confimation-dialog">
                    <div className="d-flex">
                        {!this.props.modalType && <span
                            className={`vismaicon vismaicon-lg vismicon-dynamic vismaicon-filled me-5 ${confirmIcon}`}/>}
                        {this.props.children}
                        {this.props.moreContent}
                        <p className="paragraph-space">{this.props.message}</p>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="float-end">
                        {this.props.enableSaveDiscard ?
                            <div>
                                <button className={`btn btn-margin-right btn-default`}
                                        onClick={this.discard}
                                        ref={this.discardButtonRef}
                                        tabIndex={TAB_INDEX + 2}>{this.props.translate("popUp.confirm.discard")}
                                </button>

                                <button className={`btn btn-margin-right btn-default`}
                                        onClick={this.save}
                                        onBlur={() => {
                                            this.closeButtonRef.current.focus();
                                        }}
                                        tabIndex={TAB_INDEX + 3}>{this.props.isHandleMultipleActions ?
                                                this.props.translate("popUp.confirm.saveAll"):
                                                this.props.translate("popUp.confirm.save")}
                                </button>
                            </div>
                            :
                            <div>
                                <button className={`btn btn-margin-right ${confirmButtonColor}`}
                                        onClick={this.handleAction}
                                        ref={this.okButtonRef}
                                        tabIndex={TAB_INDEX + 2}>{this.props.translate(confirmButtonText)}</button>
                                {!this.props.hideClose &&
                                    <button className="btn btn-default"
                                            onClick={this.props.closeCallback}
                                            onBlur={() => {
                                                this.closeButtonRef.current.focus();
                                            }}
                                            tabIndex={TAB_INDEX + 3}>{this.props.translate(cancelButtonText)}</button>
                                }
                            </div>

                        }
                    </div>
                </div>
            </div>

        )
            ;
    }

    getType() {
        if (this.props.popUpType)
            return this.props.popUpType;
        return CONFIRM_POPUP_TYPE;
    }

}

export default Confirm;
