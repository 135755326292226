import * as call from "./api.impl";
import {CONTEXT_ID_HEADER, RequestBuilder} from "./api.impl";
import {IF_MATCH_HEADER} from "utils/constants";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import {launchDarkly} from "utils/launchDarkly";

/**
 * direct url for backend .. that hassle with existence of variable is because of unit tests
 */
// eslint-disable-next-line
export const APPROVAL = ((typeof window.ENVIRONMENT !== 'undefined') ? window.ENVIRONMENT.APPROVAL_API : "/") + "jaxrs/";
export const APPROVAL_API_ROOT = ((typeof window.ENVIRONMENT !== 'undefined') ? window.ENVIRONMENT.APPROVAL_API : "/") + "jaxrs";

const IGNORE_VISMA_CONTEXTID = true;

function getUseNewApiFlag() {
    return launchDarkly.userClient && launchDarkly.userClient.variation("new-company-bean-api", false);
}

export function getUserData() {
    return new RequestBuilder(APPROVAL_API_ROOT)
        .path("startup")
        .get();
}

export function getUserContexts() {
    return call.get(APPROVAL + 'UserContextBean/contexts');
}

export function getAppMenuItems() {
    return call.get(APPROVAL + 'UserContextBean/menuItems');
}

export function getWorkflowGraph(taskKey) {
    return call.get(APPROVAL + "WorkflowGraphBean/get/" + taskKey);
}

export function getWorkflowGraphData(taskKey) {
    //return call.get(APPROVAL + "mock/WorkflowGraphBean/data");
    return call.get(APPROVAL + "WorkflowGraphBean/data/" + taskKey);
}

export function getWorkflowGraphDataForDocumentOverview(documentKey, companyId) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("documents")
            .path(documentKey)
            .path("progress-details")
            .get();
    }
    return call.get(APPROVAL + "DocumentOverviewBean/statusDetails/" + documentKey);
}

export function getHistoryDocumentInfo(taskId) {
    return call.get(APPROVAL + "WorkflowHistoryBean/getProcessInfo/" + taskId);
}

export function changeUserContext(contextId) {
    return call.get(APPROVAL + 'startup?contextSelector:switchContext=' + contextId);
}

export function userSearch(prefix, taskId = undefined, excludeSysAdmins = true) {
    let data = {
        prefix: prefix,
        excludeSysAdmins: excludeSysAdmins
    }
    if (taskId) {
        if (Array.isArray(taskId)) {
            multipleTasksUserSearch(data, taskId);
        } else {
            singleTaskUserSearch(data, taskId);
        }
    }
    //  if (taskId)
    //    data["task_key"] = taskId;
    return call.postForm(APPROVAL + "UserBean/userSearch", data);
}

export function singleTaskUserSearch(data, taskId) {
    if (taskId)
        data["task_key"] = taskId;
    return call.postForm(APPROVAL + "UserBean/userSearch", data);
}

export function multipleTasksUserSearch(data, taskId) {
    if (taskId)
        data["selected_tasks"] = JSON.stringify(taskId);
    return call.postForm(APPROVAL + "UserBean/userSearch", data);
}

export function getCompanyUser(userId) {
    return new Promise((resolve, reject) => {
        if (userId == null) {
            reject("userId cannot be null");
            return;
        }
        call.get(APPROVAL + "UserBean/companyuser/" + userId).then(response => {
            // response.idUser != null is necessary because response === true when the http status code is 204
            if (response != null && response.idUser != null) {
                resolve(response);
            } else {
                resolve({
                    valid: false,
                    idUser: userId,
                    fullNameOrEmail: null
                });
            }
        }, error => {
            reject(error);
        });
    });
}

export function getWsToken() {
    return call.get(APPROVAL + "token/websocket");
}

export function getDecisionTablesToken() {
    return call.get(APPROVAL + "token/decisiontable");
}

export function getDecisionTables(token) {
    let header = {
        "Authorization": "Bearer " + token
    };
    return call.getWithJsonInHeaders("https://api.approval.aws.stag.visma.net/decision-tables/decisionTables", header);
}

export function createDecisionTable(token, table) {
    let header = {
        "Authorization": "Bearer " + token
    };
    return call.postWithJsonInHeaders("https://api.approval.aws.stag.visma.net/decision-tables/decisionTables", table, header);
}

export function deleteDecisionTable(token, etag, uuid) {
    let header = {
        "Authorization": "Bearer " + token,
        "If-Match": etag
    };
    return call._deleteWithJsonHeaders("https://api.approval.aws.stag.visma.net/decision-tables/decisionTables/" + uuid, header);
}

export function updateDecisionTable(token, etag, uuid, data) {
    let header = {
        "Authorization": "Bearer " + token,
        "If-Match": etag
    };
    return call.putWithJsonInHeaders("https://api.approval.aws.stag.visma.net/decision-tables/decisionTables/" + uuid, header, data);
}


export function getDecisionTable(token, uuid, status) {
    let header = {
        "Authorization": "Bearer " + token,
        "Access-Control-Expose-Headers": "etag"
    };
    return call.getWithJsonInHeaders("https://api.approval.aws.stag.visma.net/decision-tables/decisionTables/" + uuid + "/" + status, header, false, true);
}


export function getAvailableColumnsForDocType(docType) {
    return call.get(APPROVAL + "CompanyMetadataBean/availableColumnsForDocumentType/" + docType);
}

/**
 * backend doesn't expose elementary function for each of user settings, so we have to load all, modify and save all (see application.reducer#getUserSettings)
 * see user.action.js#hideGuidedTour for example of usage
 * @param userSettings
 */
export function updateUserSettings(userSettings = {}) {
    return call.postJson(APPROVAL + "UserSettingsBean/userSettings", userSettings);
}

export function getWorkflowsAmountLimits() {
    return call.get(APPROVAL + "AmountLimitBean/list");
}

export function getAmountLimitDetails(id) {
    return call.get(APPROVAL + "AmountLimitBean/getAmountLimitDetails/" + id);
}

export function deleteAmountLimit(id, confirm) {
    let data = {
        limitId: id,
        confirm: confirm
    };
    return call.postForm(APPROVAL + "AmountLimitBean/delete", data);
}

export function deleteCurrency(id, currency) {
    let data = {
        limitId: id,
        currency: currency
    };
    return call.postForm(APPROVAL + "AmountLimitBean/deleteCurrency", data);
}

export function editAmountLimit(id, name) {
    let data = {
        limitId: id,
        name: name
    };
    return call.postForm(APPROVAL + "AmountLimitBean/editAmountLimit", data);
}

export function addAmountLimit(name) {
    let data = {
        name: name
    };
    return call.postForm(APPROVAL + "AmountLimitBean/addAmountLimit", data);
}

export function addCurrencyAmountLimit(id, currency, amount) {
    let data = {
        limitId: id,
        currency: currency,
        amount: amount
    };
    return call.postForm(APPROVAL + "AmountLimitBean/addCurrencyAmountLimit", data);
}

export function getAllCurrencies() {
    return call.get(APPROVAL + "RuleConfigurationBean/getAllCurrencies");
}


//region Accounting

export function getCostUnitTypes() {
    return call.get(APPROVAL + "cost-unit-types", null, true);
}

export function setCostUnitTypes(data, version) {
    let header = {};
    header[IF_MATCH_HEADER] = version;
    return call.postForm(APPROVAL + "RuleConfigurationBean/setCostUnitsTypesOrder", data, header);
}

export function lookupAccount(data) {
    return call.get(APPROVAL + "glas" + call.objectToQueryString(data));
}

export function _lookupAllAccounts(taskId, data) {
    return call.get(APPROVAL + "AccountingGrid/" + taskId + "/glas" + call.objectToQueryString(data));
}

export function _lookupAllCostCenters(ids, data) {
    let [taskId, index] = ids;
    return call.get(APPROVAL + "AccountingGrid/" + taskId + "/columns/" + index + "/cost-units" + call.objectToQueryString(data));
}

export function lookupAllCostCenters(ids, data) {
    let [taskId, index] = ids;
    return call.postForm(APPROVAL + "Accounting/lookupCostCenters/" + taskId + "/" + index, data);
}

export function lookupCostCenters(data, dimension) {
    return call.get(APPROVAL + "cost-unit-types/" + dimension + "/cost-units" + call.objectToQueryString(data));
}

export function showHistorySearch() {
    return call.get(APPROVAL + "Accounting/showSearch");
}

export function _updateAccoutingCell(taskId, rowid, cellid, value, rowVersion) {
    let data = {
        columnId: cellid,
        value: value
    }
    let header = {
        "If-Match": rowVersion
    };
    return call.putWithJsonInHeaders(APPROVAL + "AccountingGrid/" + taskId + "/rows/" + rowid, header, data);
}


export function _accoutingLineAction(taskId, rowId, status, rowVersion) {
    let header = {
        "If-Match": rowVersion
    };
    return call.postWithJsonInHeaders(APPROVAL + "AccountingGrid/" + taskId + "/lineapproval/" + rowId + "/" + status, null, header);
}

export function _deleteAccoutingRow(taskId, rowIndex, rowVersion) {
    let header = {
        "If-Match": rowVersion
    };
    return call._deleteWithJsonHeaders(APPROVAL + "AccountingGrid/" + taskId + "/rows/" + rowIndex, header);
}

export function addNewAccoutingRow(taskId) {
    return call.postJson(APPROVAL + "AccountingGrid/" + taskId + "/rows");
}

export function _findAccount(taskId, value) {
    const data = {
        prefix: value
    };
    return call.get(APPROVAL + "AccountingGrid/" + taskId + "/glas-indexed" + call.objectToQueryString(data));
}

export function findCostCenter(ids, value) {
    let [taskId, columnId] = ids;
    const data = {
        key: taskId,
        column: columnId,
        prefix: value
    };
    return call.postForm(APPROVAL + "Accounting/findCostCenters", data);
}

export function _findCostCenter(ids, value) {
    let [taskId, columnId] = ids;
    let data = {
        prefix: value
    }
    return call.get(APPROVAL + "AccountingGrid/" + taskId + "/columns/" + columnId + "/cost-units-indexed/" + call.objectToQueryString(data));
}

export function _getAccountingGridRows(taskKey) {
    return call.get(APPROVAL + "AccountingGrid/" + taskKey + "/rows");
}

export function _getAccountingGridHeader(taskKey) {
    return call.get(APPROVAL + "AccountingGrid/" + taskKey);
}

//endregion

//region CommentBean

export function getTaskComments(taskKey) {
    return call.get(APPROVAL + "CommentBean/getRawComments/" + taskKey);
}

export function getLastComment(taskKey) {
    return call.get(APPROVAL + "CommentBean/getLastComment/" + taskKey);
}

export function insertComment(taskId, comment) {
    const data = {
        key: taskId,
        text: encodeURIComponent(comment)
    }
    return call.postForm(APPROVAL + "CommentBean/insertComment", data);
}

//endregion

//region new Comments endpoints

export function insertTasksComment(taskKey, comment) {
    const data = {
        comment: comment
    }
    return call.postJson(APPROVAL + "tasks/" + taskKey + "/comments", data);
}

export function insertDocumentsComment(documentKey, comment) {
    const data = {
        comment: comment
    }
    return call.postJson(APPROVAL + "documents/" + documentKey + "/comments", data);
}

export function insertProcessesComment(processKey, comment) {
    const data = {
        comment: comment
    }
    return call.postJson(APPROVAL + "processes/" + processKey + "/comments", data);
}

export function getDocumentComments(documentKey) {
    return call.get(APPROVAL + "documents/" + documentKey + "/comments");
}

//endregion

//region CompanyBean

export function getCompanyDocumentTypes(companyId) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("document-types")
            .get();
    }
    return call.get(APPROVAL + "CompanyBean/getCompanyDocumentTypes");
}

export function getCompanyDocumentTypesBasedOnFeatures(companyId) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("document-types-based-on-features")
            .get();
    }
    return call.get(APPROVAL + "CompanyBean/getCompanyDocumentTypesBasedOnFeatures");
}

export function getDocumentTypesConfiguration(companyId) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("document-types-configurations")
            .get();
    }

    return call.get(APPROVAL + "CompanyBean/documentTypesConfiguration");
}

export function getDocumentTypesForWorkflowConfiguration(companyId) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("document-types-for-workflow-configuration")
            .get();
    }
    return call.get(APPROVAL + "CompanyBean/documentTypesForWorkflowConfiguration");
}

export function postDocumentTypesConfiguration(companyId, data) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("document-types-configurations")
            .jsonData(data)
            .post();
    }
    return call.postJson(APPROVAL + "CompanyBean/documentTypesConfiguration", data);
}

export function getCurrencyCode(companyId) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("default-currency-code")
            .get();
    }
    return call.get(APPROVAL + "CompanyBean/getCurrencyCode");
}

export function getEnabledFeaturesForDocumentType(documentType, companyId) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("document-types")
            .path(documentType)
            .path("enabled-features")
            .get();
    }
    return call.get(APPROVAL + "CompanyBean/enabledFeaturesForDocumentType/" + documentType);
}


//endregion

//region DocumentOverview

export function getDocumentOverview(tab, counter, data, companyId) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("documents")
            .path("search-by-status")
            .path(counter)
            .queryParam('documentType', tab.toString())
            .formData(data)
            .post();
    }
    return call.postForm(APPROVAL + "DocumentOverviewBean/list/" + tab + "/" + counter, data);
}

export function getDocumentOverviewCounter(companyId) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("documents")
            .path("counters")
            .get();
    }
    return call.get(APPROVAL + "DocumentOverviewBean/counters");
}

export function getDocumentOverviewDetails(key, companyId) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("documents")
            .path(key)
            .path("document-details")
            .get();
    }
    return call.get(APPROVAL + "DocumentOverviewBean/documentDetails/" + key);
}

export function approveDocumentDirectly(comment, selectedDocuments, companyId) {
    let data = {
        selected_documents: JSON.stringify(selectedDocuments),
        comment: comment
    };

    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("documents")
            .path("approve-directly")
            .formData(data)
            .post();
    }
    return call.postForm(APPROVAL + "DocumentOverviewBean/approveDirectly", data);
}

export function rejectDocumentDirectly(comment, selectedDocuments, companyId) {
    let data = {
        selected_documents: JSON.stringify(selectedDocuments),
        comment: comment
    };
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("documents")
            .path("reject-directly")
            .formData(data)
            .post();
    }
    return call.postForm(APPROVAL + "DocumentOverviewBean/rejectDirectly", data);
}

export function sendDocumentForApproval(comment, selectedDocuments, companyId) {
    let data = {
        selected_documents: JSON.stringify(selectedDocuments),
        comment: comment
    };
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("documents")
            .path("send-for-approval")
            .formData(data)
            .post();
    }
    return call.postForm(APPROVAL + "DocumentOverviewBean/sendForApproval", data);
}

export function getDocumentOverviewEditor(taskKey, vPageView, companyId) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("documents")
            .path(taskKey)
            .path("external-editor")
            .get();
    }
    return call.get(APPROVAL + "DocumentOverviewBean/getExternalEditor/" + taskKey, vPageView);
}

//endregion

//region Departments

export function getDepartments() {
    return call.get(APPROVAL + "DepartmentManagerBean/Departments");
}

export function getAssignedDepartmentManagers() {
    return call.get(APPROVAL + "DepartmentManagerBean/AssignedDepartmentManagers");
}

export function getPossibleDepartmentManagers() {
    return call.get(APPROVAL + "DepartmentManagerBean/PossibleDepartmentManagers");
}

export function saveDepartmentManagers(data) {
    return call.postJson(APPROVAL + "DepartmentManagerBean/DepartmentManagers", data);
}


//endregion

//region NearestManagers

export function getNearestManagers(page, itemsPerPage, nearestManagerMode, asc, name) {
    const data = {
        page: page,
        itemsPerPage: itemsPerPage,
        nearestManagerMode: nearestManagerMode,
        asc: asc,
        name: name
    };
    return call.get(APPROVAL + "NearestManagers" + call.objectToQueryString(data));
}

//endregion


//region FileUpload

export function getHistoryAttachments(taskId) {
    return call.get(APPROVAL + "FileUpload/getAttachmentPageKeysNewUI/" + taskId);
}

export function deleteAttachment(taskId, collectionId, attachmentId) {
    return new RequestBuilder(APPROVAL_API_ROOT)
        .path("FileUpload")
        .path("delete")
        .path(taskId)
        .path(collectionId)
        .path(attachmentId)
        .post();
}

export function addAttachment(taskId, title, description, file) {
    const data = new FormData();
    data.append('title', title);
    data.append('description', description);
    data.append('input_browse', file);

    return new RequestBuilder(APPROVAL_API_ROOT)
        .path("FileUpload")
        .path("upload")
        .path(taskId)
        .multipartData(data)
        .post();
}

export function getDocumentOverviewAttachments(taskId) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("documents")
            .path(taskId)
            .path("attachments")
            .get();
    }
    return call.get(APPROVAL + "FileUpload/getDocumentAttachmentPageKeysNewUI/" + taskId);
}

//endregion

//region HistoryBean

export function getHistoryTasks(data) {
    let {type} = data;
    return call.postForm(APPROVAL + "HistoryBean/" + type, data);
}

export function getProcessStates() {
    return call.get(APPROVAL + "HistoryBean/getProcessStates/");
}

//endregion

//region MailBean

export function emailTask(taskId, to, comment, subject, withAttachment, withCopyToMe, cc, bcc) {
    const data = {
        key: taskId,
        to: to,
        message: comment,
        subject: subject,
        sendAttachment: withAttachment,
        sendCopy: withCopyToMe,
        cc: cc,
        bcc: bcc
    };
    const formData = {
        mailreq: JSON.stringify(data)
    };
    return call.postForm(APPROVAL + "MailBean/sendTaskMail", formData);
}

export function emailDocument(taskId, to, comment, subject, withAttachment, withCopyToMe, cc, bcc, companyId) {
    const data = {
        key: taskId,
        to: to,
        message: comment,
        subject: subject,
        sendAttachment: withAttachment,
        sendCopy: withCopyToMe,
        cc: cc,
        bcc: bcc
    };
    const formData = {
        mailreq: JSON.stringify(data)
    };

    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("documents")
            .path("send-by-email")
            .formData(formData)
            .post();
    }
    return call.postForm(APPROVAL + "MailBean/sendDocumentMail", formData);
}

export function getUserEmails(prefix) {
    return call.postForm(APPROVAL + "MailBean/findAddressBookEmails", {
        prefix: prefix
    });
}

//endregion

//region MailSettingsBean

export function getEmailSettings() {
    return call.get(APPROVAL + "MailSettingsBean/getMailSettings");
}

export function updateEmailSettings(data) {
    return call.postJson(APPROVAL + "MailSettingsBean/updateMailSettings", data);
}

//endregion

//region OrganizationChart

export function getOrganizationChart() {
    return call.get(APPROVAL + "OrganizationChart/listRoles");
}

export function getOrganizationChartForDate(date, data) {
    return call.postForm(APPROVAL + "OrganizationChart/getChildren/" + date, data);
}

export function getOrganizationChartRoles(roleId) {
    return call.get(APPROVAL + "OrganizationChart/getRoles/" + roleId);
}

export function getOrganizationChartUsers(date, unitId, level, data) {
    return call.postForm(APPROVAL + "OrganizationChart/getUsers/" + unitId + "/" + level + "/" + date, data);
}


//endregion

//region Payment Approval

export function getActiveBatches() {
    return call.get(APPROVAL + "paymentApproval/batches/active");
}

export function createBatch() {
    return call.putJson(APPROVAL + "paymentApproval/batches/");
}

export function addToBatch(batchId, version, taskId) {
    let data = {
        version: version,
        taskId: taskId
    };
    return call.putJson(APPROVAL + "paymentApproval/batches/" + batchId + "/tasks", data);
}

export function changeBatch(batchId, version, state, secret) {
    let data = {
        version: version,
        state: state
        //secret: secret
    };
    if (secret)
        data.secret = secret;
    return call.postJson(APPROVAL + "paymentApproval/batches/" + batchId, data);
}

//endregion

//region ProjectManagerBean

export function getGeneralApprovers() {
    return call.get(APPROVAL + "ProjectManagerBean/GeneralApprovers");
}

export function updateGeneralApprovers(data) {
    return call.postJson(APPROVAL + "ProjectManagerBean/GeneralApprover", data);
}

export function deleteGeneralApprovers(id) {
    return call._delete(APPROVAL + "ProjectManagerBean/GeneralApprover/" + id);
}


//endregion

//region RoleChartBean

export function getRuleConfigurationRoles() {
    return call.get(APPROVAL + "Roles");
}

export function getRuleConfigurationRoleById(id) {
    return call.get(APPROVAL + `Roles/${id}`);
}

export function addRuleConfigurationRoles(data) {
    let postData = {
        name: data
    };
    return call.postJson(APPROVAL + "Roles", postData);
}

export function updateRuleConfigurationRoles(data) {
    const id = data.idRole;
    let postData = {
        name: data.name,
        roleId: data.idRole
    };
    return call.putJson(APPROVAL + `Roles/${id}`, postData);
}

export function deleteRuleConfigurationRoles(id) {
    return call._delete(APPROVAL + `Roles/${id}`);
}

export function getCostUnitNodes() {
    return call.get(APPROVAL + "CostUnitNodes");
}

/**
 * @typedef {Object} AddCostUnitNodeRequest
 * @property {Object.<number, string|null>} costUnits
 */
/**
 *
 * @param {AddCostUnitNodeRequest} nodes
 * @param {number} xversion
 * @returns {BluebirdPromise<any>}
 */
export function saveCostUnitNodes(nodes, xversion) {
    return call.postJson(APPROVAL + "CostUnitNodes", nodes, xversion);
}

export function getCostUnitsCombinations(nodeid, level, parentid) {
    let data = {
        page: 1,
        rows: 10000,
        sord: "asc",
        nodeid: nodeid,
        parentid: parentid,
        n_level: level
    };
    return call.get(APPROVAL + `CostUnitNodes/${nodeid}/children` + call.objectToQueryString(data));
}

export function getBranches() {
    return call.get(APPROVAL + "Branch");
}

export function getExceptions() {
    return call.get(APPROVAL + "RoleNameExceptions");
}

export function getExceptionsExtended() {
    return call.get(APPROVAL + "RoleNameExceptions/exceptions");
}

export function getRoleNameExceptions() {
    return call.get(APPROVAL + "RoleNameExceptions");
}

export function searchExceptionsRole(text) {
    let data = {
        q: text
    };
    return call.get(APPROVAL + "Roles" + call.objectToQueryString(data));
}

export function getExceptionsRole(role) {
    return call.get(APPROVAL + `Roles/${role}`);
}

export function saveExceptions(exceptions) {
    let data = {
        roleNameExceptionDTOList: exceptions
    };
    return call.putJson(APPROVAL + "RoleNameExceptions", data);
}

export function saveRoleNameException(data) {
    return call.postJson(APPROVAL + "RoleNameExceptions", data);
}

export function updateRoleNameException(id, data) {
    return call.putJson(APPROVAL + `RoleNameExceptions/${id}`, data);
}

export function deleteRoleNameException(id) {
    return call._delete(APPROVAL + `RoleNameExceptions/${id}`);
}

export function getCostUnitRoles(id, level, sortColumn = "approver", sortOrder = "asc") {
    let formData = {
        approver: '',
        role: 'all',
        costCenterType: 'all',
        costUnitName: '',
        page: 1,
        rows: 50,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        level: level
    };

    return call.get(APPROVAL + "CostUnitNodes/" + id + "/assignments" + call.objectToQueryString(formData));
}

export function getCostUnitWarnings() {
    return call.get(APPROVAL + "CostUnitNodes/dimensionWarnings");
}

export function getDeletedCostUnitWarnings(data) {
    return call.get(APPROVAL + "CostUnitNodes/warnings" + call.objectToQueryString(data));
}


export function deleteCostUnitNode(id, confirm, version) {
    let data = {
        confirm: confirm
    };
    return call._delete(APPROVAL + "CostUnitNodes/" + id + call.objectToQueryString(data), version);
}

export function deleteCostUnitRole(nodeId, userId, roleId, branchId) {
    let url = APPROVAL + "CostUnitNodes/" + nodeId + "/assignments/" + userId + "/" + roleId
    if (branchId)
        url += "/" + branchId;

    return call._delete(url);
}

export function editCostUnitRole(nodeId, oldRoleId, oldUserId, oldBranchId, roleId, userId, branchId, deputy) {
    let data = {
        roleId: roleId,
        userId: userId,
        deputy: deputy,
        branchId: branchId === 0 ? null : branchId
    };

    let url = APPROVAL + "CostUnitNodes/" + nodeId + "/assignments/" + oldUserId + "/" + oldRoleId;
    if (oldBranchId)
        url += "/" + oldBranchId;

    return call.putJson(url, data);
}

export function saveCostUnitRole(id, roleId, userId, branchId, deputy) {
    let data = {
        costUnitNodeId: id,
        roleId: roleId,
        userId: userId,
        branchId: branchId === 0 ? null : branchId,
        deputy: deputy
    };
    return call.postJson(APPROVAL + "CostUnitNodes/" + id + "/assignments", data);
}

export function getNodeAncestors(id) {
    return call.get(APPROVAL + "CostUnitNodes/" + id + "/ancestors");
}


//endregion

//region RuleConfiguration

export function activateRule(rule, state) {
    rule.active = state;
    return call.putJson(APPROVAL + "RuleConfigurationBean/RuleConfiguration/" + rule.idRuleConfiguration, rule);
}

export function activateReadonlyRule(ruleConfigurationId) {
    return call.postJson(APPROVAL + "RuleConfigurationBean/RuleConfiguration/" + ruleConfigurationId + "/activate");
}

export function deactivateReadonlyRule(ruleConfigurationId) {
    return call.postJson(APPROVAL + "RuleConfigurationBean/RuleConfiguration/" + ruleConfigurationId + "/deactivate");
}

export function addRule(name, docType) {
    let data = {
        name: name,
        documenttype:
            {id: docType},
        steps: [],
        active: false
    };
    return call.postJson(APPROVAL + "RuleConfigurationBean/RuleConfiguration", data);
}

export function copyRule(ruleId) {
    return call.postJson(APPROVAL + "RuleConfigurationBean/CopyRuleConfiguration/" + ruleId);
}

export function copyRuleWithName(rule) {
    return call.postJson(APPROVAL + "RuleConfigurationBean/RuleConfiguration/", rule);
}

export function deleteRule(ruleId, comment) {
    if (comment) {
        let data = {
            deleteReason: comment
        };
        return call._deleteWithJsonHeaders(APPROVAL + "RuleConfigurationBean/RuleConfiguration/" + ruleId, data);
    }
    return call._delete(APPROVAL + "RuleConfigurationBean/RuleConfiguration/" + ruleId);
}

export function getCopiedRuleConfiguration(ruleId) {
    return call.get(APPROVAL + "RuleConfigurationBean/RuleConfiguration/" + ruleId);
}

export function getRuleConfiguration() {
    return call.get(APPROVAL + "RuleConfigurationBean/RuleConfiguration");
}

export function saveRule(rule, comment) {
    if (comment) {
        let data = {
            updateReason: comment
        };
        return call.putWithJsonInHeaders(APPROVAL + "RuleConfigurationBean/RuleConfiguration/" + rule.idRuleConfiguration, data, rule);
    } else {
        return call.putJson(APPROVAL + "RuleConfigurationBean/RuleConfiguration/" + rule.idRuleConfiguration, rule);
    }
}

export function SimulateWorkflow(key) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("documents")
            .path(key)
            .path("simulate")
            .post();
    }
    return call.postJson(APPROVAL + "RuleConfigurationBean/Proofing/" + key);
}

export function workflowSimulator(config, document) {
    return call.postForm(APPROVAL + "RuleConfigurationBean/Simulator/", {
        config: JSON.stringify(config),
        document: JSON.stringify(document)
    });
}

export function getSimulatorProjects() {
    return call.get(APPROVAL + "ProjectManagerBean/Projects");
}

export function getSimulatorDepartments() {
    return call.get(APPROVAL + "DepartmentManagerBean/Departments");
}

export function findEmployees(prefix) {
    let data = {
        prefix: prefix
    };
    return call.postForm(APPROVAL + "RuleConfigurationBean/findEmployees/", data);
}

//endregion

//region SubstituteBean

export function getSubstitutesSortedOnPage(page, typeFilter, prefix, sort) {
    const data = {
        prefix: prefix,
        interval: typeFilter,
        fields: JSON.stringify([sort]),
        page: page,
        itemsPerPage: 13
    };

    return call.get(APPROVAL + "substitute" + call.objectToQueryString(data));
}

export function saveSubstitute(data) {
    return call.postJson(APPROVAL + "substitute", data);
}

export function validateSubstitute(data) {
    return call.postJson(APPROVAL + "substitute/validate", data);
}

export function getSubstitute(id) {
    return call.get(APPROVAL + "substitute/" + id);
}

//endregion

//region SystemOverview / ProcessOverview

export function getProcessOverview(data, type) {
    return call.postForm(APPROVAL + "SystemOverviewBean/" + type, data);
}

export function getProcessOverviewSse(filters, conf, cursor) {
    let headers = {
        'X-Requested-With': 'UI'
    };
    // This is the only way to use a constant for the value of the key
    headers[CONTEXT_ID_HEADER] = window.currentContext;
    if (cursor != null) {
        headers['last-event-id'] = cursor;
    }

    let url = "SystemOverviewBean/stream?limit=40";
    if (filters.columns != null) {
        for (const [column, value] of Object.entries(filters.columns)) {
            url = url.concat("&" + encodeURIComponent(column) + "=", encodeURIComponent(value));
        }
    }
    if (filters.costUnits != null && filters.costUnits.length > 0) {
        url = url.concat("&costUnits=", encodeURIComponent(JSON.stringify(filters.costUnits)));
    }
    if (filters.account != null) {
        url = url.concat("&account=", encodeURIComponent(JSON.stringify(filters.account)));
    }
    return fetchEventSource(APPROVAL + url, {
        signal: conf.signal,
        headers: headers,
        credentials: 'include',
        openWhenHidden: true,
        onopen: conf.onopen,
        onmessage: conf.onmessage,
        onclose: conf.onclose,
        onerror: conf.onerror
    });
}

export function getProcessOverviewStates() {
    return call.get(APPROVAL + "SystemOverviewBean/getProcessStates/");
}

export function getAllProcessOverviewStates() {
    return call.get(APPROVAL + "SystemOverviewBean/getAllProcessStates/");
}


//endregion

//region TaskBean

export function postponeTask(taskKeys, comment, formattedNewDueDate, daysAfterTaskDueDate, daysBeforeDocumentDueDate) {

    const data = {
        taskKeys: taskKeys,
        /**
         * Format: yyyy-MM-dd
         */
        formattedNewDueDate: formattedNewDueDate,
        comment: comment,
        daysAfterTaskDueDate: daysAfterTaskDueDate,
        daysBeforeDocumentDueDate: daysBeforeDocumentDueDate
    };

    return call.postJson(APPROVAL + "TaskBean/postpone", data);
}

export function getDocumentEditor(taskKey, vPageView) {
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tasks")
            .path(taskKey)
            .path("external-editor")
            .get();
    }
    return call.get(APPROVAL + "TaskBean/getExternalEditor/" + taskKey, vPageView);
}

export function updateTask(taskKey, version, skip) {
    let data = {
        documentVersion: version,
        skipWorkflowRestart: skip
    };
    return call.postJson(APPROVAL + "WorkflowActions/updateDocument/" + taskKey, data);
}

export function updateDocument(documentKey, version, skip, companyId) {
    let data = {
        documentVersion: version,
        skipWorkflowRestart: skip
    };
    const useNewApi = getUseNewApiFlag();
    if (useNewApi) {
        return new RequestBuilder(APPROVAL_API_ROOT, IGNORE_VISMA_CONTEXTID)
            .path("tenant")
            .path(companyId)
            .path("documents")
            .path(documentKey)
            .path("update-document")
            .post();
    }
    return call.postJson(APPROVAL + "DocumentOverviewBean/updateDocument/" + documentKey, data);
}

export function getTaskDocumentDetails(taskKey) {
    return call.get(APPROVAL + "TaskBean/documentDetails/" + taskKey);
}

export function postTaskDocumentDetails(taskKey, data) {
    return call.postJson(APPROVAL + "TaskBean/documentDetails/" + taskKey, data);
}

export function getAllTasks() {
    return call.get(APPROVAL + "TaskBean/getAllTasksForUser");
}

export function getListOfTasks(taskIds) {
    return call.get(APPROVAL + "TaskBean/getAllTasksForUser?taskIds=" + encodeURIComponent(taskIds));
}

export function getTaskDetails(taskKey) {
    return call.get(APPROVAL + "TaskBean/getTaskDetails/" + taskKey);
}


//endregion

//region Task Overview

export function getTaskOverviewCounters() {
    return call.get(APPROVAL + "TaskOverviewBean/getAllTaskOverviewCounters");
}

export function getAssignmentType() {
    return call.get(APPROVAL + "TaskOverviewBean/getAssignmentType");
}

export function getTaskOverview(data) {
    return call.postForm(APPROVAL + "TaskOverviewBean/getTaskOverview", data);
}

export function OverviewReassign(taskKeys, comment, recipient) {

    const data = {
        selectedTasks: taskKeys,
        comment: comment,
        recipient: recipient
    };
    return call.postJson(APPROVAL + "TaskOverviewBean/reassign", data);
}


//endregion

//region WorkflowActions

export function approveTask(taskKey, comment = "", docVersion, skipWorkflowRestart) {
    let data = {};
    if (comment)
        data.comment = comment;
    if (docVersion)
        data.documentVersion = docVersion;
    if (skipWorkflowRestart)
        data.skipWorkflowRestart = skipWorkflowRestart;
    return call.postJson(APPROVAL + "WorkflowActions/approve/" + taskKey, data);
}

export function forwardTask(taskKey, userComment, recipientId) {
    const data = {
        comment: userComment,
        recipient: recipientId
    }
    return call.postJson(APPROVAL + "WorkflowActions/forward/" + taskKey, data);
}

export function rejectTask(taskKey, comment) {
    const data = {
        comment: comment || ""
    };
    return call.postJson(APPROVAL + "WorkflowActions/reject/" + taskKey, data);
}

export function reviewTask(taskKey, comment) {
    const data = {
        comment: comment || ""
    };
    return call.postJson(APPROVAL + "WorkflowActions/reviewDone/" + taskKey, data);
}

export function requestReviewTask(taskKey, recipientId, comment) {
    const data = {
        comment: comment,
        recipient: recipientId
    };
    return call.postJson(APPROVAL + "WorkflowActions/review/" + taskKey, data);
}

//endregion


//region WorkflowHistory
export function getWorkflowHistory(data) {
    return call.get(APPROVAL + "RuleConfigurationBean/RuleConfigurationHistory" + call.objectToQueryString(data));
}

export function getRuleConfigurationHistory(ruleId) {
    let data = {
        page: 1,
        rows: 20,
        ruleConfigId: ruleId
    }
    return call.get(APPROVAL + "RuleConfigurationBean/RuleConfigurationHistory" + call.objectToQueryString(data));
}

export function getOldWorkflowJSON(ruleId, version) {
    return call.get(APPROVAL + "RuleConfigurationBean/RuleConfigurationHistory/" + ruleId + "/" + version);
}

//endregion
export function cancelRequests(requests) {
    return requests.forEach(p => p.cancel());
}