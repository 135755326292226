import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as reduxSelectors from "components/store/application.reducers";
import { changeUnsavedComment, taskCommentStartSaving } from "components/taskdetails-remake/taskDetails.action";
import { ActionlessCommentsProps } from "types/comments";
import translate from "components/translations/translations.wrapper";
import CommentsPanelComponent from "components/taskdetails-remake/panels/newcomments/CommentsPanel.component";
import { COMMENT_SAVING_STATUS } from "utils/constants";
import { snowplowTrackEvent } from "utils/snowplow";
import { Store } from "redux";

const ActionlessComments = (props: ActionlessCommentsProps) => {
    const [currentComment, setCurrentComment] = useState<string | null>(null);
    const [offline, setOffline] = useState(false);
    const commentsCount = useSelector(reduxSelectors.getTaskCommentsCount);
    const isLoading = useSelector(reduxSelectors.getTaskCommentsLoadingStatus);
    const taskUnsavedComment = useSelector(reduxSelectors.getTaskUnsavedComment);
    const savingCommentStatus = useSelector(reduxSelectors.getTaskCommentSavingStatus);
    const userData = useSelector((store: Store) => reduxSelectors.getUsersData(store));
    const [saveTimeout, setSaveTimeout] = useState(undefined);
    const dispatch = useDispatch();

    useEffect(() => {
        requestData(props.taskId);
    }, [props.taskId]);

    useEffect(() => {
        if (currentComment !== taskUnsavedComment)
            setCurrentComment(taskUnsavedComment);
    }, [taskUnsavedComment]);

    useEffect(() => {
        if (savingCommentStatus === COMMENT_SAVING_STATUS.START && currentComment) {
            if (saveTimeout !== undefined) {
                clearTimeout(saveTimeout);
            }
            addNewComment();
        }
    }, [savingCommentStatus]);

    useEffect(() => {
        const search = setTimeout(() => {
            updateTaskUnsavedComment(currentComment);
        }, 1000);
        return () => clearTimeout(search);
    }, [currentComment]);

    const requestData = (taskId: string) => {
        if (props.getCommentsHandler)
            props.getCommentsHandler(taskId);
    };

    const addNewComment = () => {
        if (!navigator.onLine) {
            setOffline(true);
            setSaveTimeout(setTimeout(addNewComment, 1000));
            return;
        } else {
            setOffline(false);
        }
        if (props.addCommentHandler) {
            props.addCommentHandler(props.taskId, currentComment);
        }
    };

    const isAddCommentDisabled = () => {
        return currentComment === null || savingCommentStatus === COMMENT_SAVING_STATUS.START || savingCommentStatus === COMMENT_SAVING_STATUS.SAVING || currentComment.length > 500 || currentComment.length === 0;
    };

    const updateTaskUnsavedComment = (comment: string) => {
        if (comment === taskUnsavedComment) {
            return;
        }

        dispatch(changeUnsavedComment(comment));
    };

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let comment = event.target.value;

        if (comment !== currentComment) {
            setCurrentComment(comment);
        }
    };

    const onAddComment = () => {
        snowplowTrackEvent({
            category: "task_detail_" + props.taskDetailsType,
            action: "add_actionless_comment",
            label: "add_comment",
        }, userData);
        dispatch(taskCommentStartSaving());
    }

    const currentCommentsCount = isLoading ? "" : commentsCount;

    const saveCommentContent = () => {
        return <div className="d-flex align-self-end mx-16"
                    data-testid={"addBtn"}>
            <button type="button"
                    className="btn btn-icon  btn-transparent btn-square"
                    disabled={isAddCommentDisabled()}
                    data-tooltip-warning={props.translate("taskDetail.sendCommentTooltip")}
                    onClick={onAddComment}
                    data-cy="taskDetail.comments.saveButton">
                <span className={"vismaicon vismaicon-paperplane-dyn "} />
            </button>
        </div>;
    };

    return <CommentsPanelComponent
        readOnly={props.readOnly}
        commentsCount={currentCommentsCount}
        comment={currentComment}
        savingCommentStatus={savingCommentStatus}
        automaticSave={false}
        offline={offline}
        onChange={onChange}
        saveCommentContent={saveCommentContent()} />
};

const withTranslations = translate(ActionlessComments);

export default withTranslations;