import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {PopUp} from './PopUp.component';
import translate from '../translations/translations.wrapper.jsx';
import {connect} from 'react-redux';
import {hideErrorMessage} from "../usercontext/user.action";
import * as OdpApi from '../../utils/api/odp.api';
import {ERROR_POPUP_TYPE} from '../../utils/constants';
import {getErrorMessageVisibility} from '../store/application.reducers';

/**
 * popup which will be used in case user has selected 'approve with comment'
 * expects as props:
 * list of selected tasks
 * close popup callback
 * translate function
 */
export class ErrorMessagePopup extends PopUp {

    static propTypes = {
        closeCallback: PropTypes.func, //callback for closing the popup
        handleAction: PropTypes.func, //callback for closing the popup
        translate: PropTypes.func, // to translate texts
        error: PropTypes.object,
        applyTranslations: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.buildContent = this.buildContent.bind(this);
        this.closeErrorMessage = this.closeErrorMessage.bind(this);

    }

    componentDidMount() {
        super.componentDidMount();
        this.okButton.focus();

        //check if there is another popup open
        //I'm not very happy with this solution, this case should happen rarely but maybe change this in the future
        let popUps = document.getElementsByClassName("pop-up");
        if (popUps.length > 1) {
            for(let i=0;i<popUps.length;i++){
                if (popUps[i].className.indexOf("ast_modal_error") === -1) {
                    popUps[i].style.display = "none";
                }
            }
        }
    }

    closeErrorMessage() {
        if (this.props.showErrorMessage && this.props.showErrorMessage.shouldRedirect) {
            // redirect to odp - do not navigate through /logout, because we'd like to help user continue with his work
            // and visiting logout would clean the store
            OdpApi.login();
            //no need to hide the message - the redirect should take place
            return;
        }

        this.props.hideErrorMessage();
    }

    getErrorText() {
        if (!this.props.showErrorMessage) {
            return "";
        }

        if (this.props.showErrorMessage.shouldRedirect) {
            return this.props.translate("popUp.error.redirectText")
        }

        let response = this.props.showErrorMessage.statusText ? this.props.showErrorMessage.statusText : this.props.translate("popUp.error.defaultText");

        if (this.props.error && this.props.error.applyTranslations && this.props.showErrorMessage.statusText) {
            response = this.props.translate(this.props.showErrorMessage.statusText);
        }

        return response;
    }

    getTitle() {
        if (this.props.showErrorMessage) {
            if (this.props.error && this.props.showErrorMessage.title) {
                return this.props.error.applyTranslations ? this.props.translate(this.props.showErrorMessage.title) : this.props.showErrorMessage.title;
            }

            return this.props.translate("popUp.error.title");
        }
    }

    buildContent() {
        let messageText = this.props.showErrorMessage ? this.getErrorText() : this.props.translate("popUp.error.defaultText");
        return (
            <div>
                <div className="modal-body message">
                    {messageText}
                </div>

                <div className="modal-footer">
                    <div className="float-right">
                        <button className="btn btn-primary btn-margin-right ast_emp_ok"
                                onClick={this.closeErrorMessage}
                                ref={(element) => {
                                    this.okButton = element;
                                }}
                                tabIndex="2">{this.props.translate("popUp.close")}</button>
                    </div>
                </div>
            </div>
        );
    }

    getType() {
        return ERROR_POPUP_TYPE;
    }

}

const withTranlations = translate(ErrorMessagePopup);

const mapStateToProps = function (store) {
    return {
        showErrorMessage: getErrorMessageVisibility(store)
    };
};

export default connect(mapStateToProps, {
    hideErrorMessage
})(withTranlations);
