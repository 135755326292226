import React, {useEffect} from "react";
import "./comment.scss";
import * as reduxSelectors from "components/store/application.reducers.js";
import { useSelector } from "react-redux";
import { launchDarkly } from "utils/launchDarkly";
import { COMMENTS_INPUT_MAX_LENGTH } from "utils/constants";
import { CommentInputProps } from "types/comments";

const Comment =  React.forwardRef((props: CommentInputProps, ref) => {

    const useNewAddComments = launchDarkly.companyClient && launchDarkly.companyClient.variation("fe-improve-add-comments", false);
    const taskComment = useSelector(reduxSelectors.getTaskUnsavedComment);
    const allowExtraComments = useSelector(reduxSelectors.getAllowExtraComments);
    const [value, setValue] = React.useState(useNewAddComments && allowExtraComments ? taskComment : props.value);
    const inputLimit = props.inputLimit != null ? props.inputLimit : COMMENTS_INPUT_MAX_LENGTH;

    useEffect(() => {
        if(!useNewAddComments || !allowExtraComments)
            changeValue(props.value);
    },[props.value]);

    useEffect(() => {
        if (useNewAddComments && allowExtraComments)
            changeValue(value);
    }, []);

    const onChange = function(event: React.ChangeEvent<HTMLTextAreaElement>) {
        const value = event.target.value;
        if (inputLimit <= 0 || value.length <= inputLimit) {
            changeValue(value);
        }
    };

    const changeValue = function(newValue: string) {
        setValue(newValue);
        props.propagateValue(newValue);
    }

    const charsLeft = function() {
        return inputLimit > 0 ?
            <span className="text-disabled col-md-12 px-0">
                    {props.translate("input.comment.charsLeft", (inputLimit - (value ? value.length : 0)))}
                </span>
            : "";
    }

    return (
        <div>
            <textarea
                // @ts-ignore
                ref={ref}
                tabIndex={props.tabIndex}
                className={props.className || "col-xs-12 comment-box-textarea ast_comment_input"}
                placeholder={props.inputHint}
                value={value || ''}
                onChange={onChange} />
            {charsLeft()}
        </div>

    );
});

export default Comment;