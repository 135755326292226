import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_CONTENT_TAB_INDEX } from "utils/constants";
import OKCancelButtons from "components/modals/OKCancelButtons";
import Comment from "components/input/Comment.component";
import { addAttachment } from "components/modals/modal.actions";
import * as reduxSelectors from "components/store/application.reducers";

const AddAttachment = ({ more, translate, closeModal, loopFocus, errorCallback }: any): React.ReactElement => {
    const titleInputRef = useRef<HTMLInputElement>(null);
    const taskDetails = useSelector(reduxSelectors.getTaskDetails);
    const initComment = useSelector(reduxSelectors.getTaskCurrentComment);

    const [title, setTitle] = useState("");
    const [comment, setComment] = useState(initComment);
    const [file, setFile] = useState<any>(null);
    const [fileTooBigMessage, setFileTooBigMessage] = useState<string>(null);
    const [actionRunning, setActionRunning] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        titleInputRef.current?.focus();
    }, []);

    const getAcceptedFiles = () => {
        let extensions = ".png, .jpg, .jpeg";
        let hint = "popUp.addAttachment.browseHintPNG";
        let  additionalTypes = "";
        if (taskDetails.taskPermission?.allowPdfUpload) {
            extensions += ", .pdf";
            hint = "popUp.addAttachment.browseHint";
        }
        if (taskDetails.taskPermission?.allowHtmlUpload) {
            extensions += ", .html";
            additionalTypes += ", HTML";
        }
        if (taskDetails.taskPermission?.allowTiffUpload) {
            extensions += ", .tiff";
            additionalTypes += ", TIFF";
        }
        return { extensions, hint, additionalTypes };
    };

    const addErrorCallback = (fileTooBigMessage: any) => {
        setActionRunning(false);
        setFileTooBigMessage(fileTooBigMessage);
        errorCallback();
    }

    const addAction = () => {
        setActionRunning(true);
        more.tasks.forEach(({ key }) => {
            const data = { key, title, comment, file, addMessage: translate("popUp.addAttachment.hasBeenAdded") };
            dispatch(addAttachment(data, closeModal, addErrorCallback));
        });
    };

    const addFile = (event: any) => {
        setFile(event.target.files[0]);
        setFileTooBigMessage(null);
    };

    const acceptedFiles = getAcceptedFiles();
    const okEnabled = comment && title && file && !actionRunning && !fileTooBigMessage;

    return (
        <div>
            <h4 className="modal-title">{more?.title || translate("popUp.addAttachment.title")}</h4>
            <div className="modal-body ast_add_attachments">
                <div className="px-0">
                    <div className="d-flex mb-2">
                        <label htmlFor="title" className="col-md-3 px-0 lh-32">
                            <span className="text-danger">* </span>
                            {translate("popUp.addAttachment.fileTitleLabel")}
                        </label>
                        <input
                            className="col-md-9 add-attachment-title align-items-end"
                            type="text"
                            id="title"
                            tabIndex={MODAL_CONTENT_TAB_INDEX + 1}
                            ref={titleInputRef}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="d-flex mb-2">
                        <label htmlFor="comment" className="col-md-3 px-0 align-middle lh-32">
                            <span className="text-danger">* </span>
                            {translate("popUp.addAttachment.commentLabel")}
                        </label>
                        <div className="col-md-9 px-0">
                            <Comment
                                inputHint={translate("popUp.approve.commentHint")}
                                inputLimit={256}
                                translate={translate}
                                tabIndex={MODAL_CONTENT_TAB_INDEX + 2}
                                propagateValue={setComment}
                                id="comment"
                                value={comment}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column mt-6">
                        <div className="d-flex flex-row">
                            <label htmlFor="fileInput" className="col-md-3 px-0 align-middle lh-32">
                                <span className="text-danger">* </span>
                                {translate("popUp.addAttachment.fileLabel")}
                            </label>
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-row">
                                    <label htmlFor="fileInput" tabIndex={MODAL_CONTENT_TAB_INDEX + 3} className="btn btn-default">
                                        <input
                                            id="fileInput"
                                            type="file"
                                            style={{ display: "none" }}
                                            accept={acceptedFiles.extensions}
                                            onChange={addFile}
                                        />
                                        {translate("popUp.addAttachment.browse")}
                                    </label>
                                    <span className={`d-flex align-items-center ${fileTooBigMessage ? "text-danger" : "text-blue-highlight bold"}`}>
                                        {file?.name}
                                    </span>
                                </div>
                                <div>
                                    <span className="text-disabled col-md-12 px-0">{translate(acceptedFiles.hint)}{acceptedFiles.additionalTypes}</span>
                                    <span className="text-danger col-md-12 px-0 py-0">{fileTooBigMessage}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OKCancelButtons
                tabIndex={MODAL_CONTENT_TAB_INDEX + 1}
                okEnabled={okEnabled}
                okCallback={addAction}
                okText={translate("popUp.ok")}
                cancelEnabled={true}
                cancelCallback={closeModal}
                cancelText={translate("popUp.cancel")}
                loopFocus={loopFocus}
            />
        </div>
    );
};

export default AddAttachment;