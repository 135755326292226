export const SaveDiscardMessage = (props) => {

    return (
        <div>
            <strong>{props.translate("popUp.unsavedChanges")}</strong>
            <p className="paragraph-space mt-4">
                {props.translate("popUp.exitWarning")}
            </p>
            <p className="paragraph-space mt-4">
                {props.iframeHasUnsavedChanges ?
                    props.translate("taskDetail.unsavedEditorChanges") :
                    props.translate("popUp.shouldContinue")
                }
            </p>
        </div>
);
};