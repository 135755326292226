import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
    formatDate,
    formatAmountValue,
    shouldShowForeignAmount,
    getTaskOverdueStatus,
    formatDocumentType
} from "utils/valueFormatter.function.js";
import '../pagelayout/global.scss';
import "./my_tasks.scss";
import {TASK_OVERDUE_STATUS, TASK_DETAILS_URL_PART, KEYS} from "utils/constants.js";
import moment from "moment";
import * as Api from '../../utils/api/api';
import {DOCUMENT_TYPE} from "utils/constants.js";
import viewIcon from "../../assets/SolidBricks_view_blue-24x24.png"
import substitute from "../../assets/24_substitute.svg";
import forwarded from "../../assets/24_forwarded_task.svg";
import review from "../../assets/24_search.svg";
import reassigned from "../../assets/24_reassign.svg";
import {Tooltip} from "../tooltip/Tooltip";

/**
 * see the expected propTypes
 */
export class TaskView extends Component {

    static propTypes = {
        task: PropTypes.object, // json for one task
        selectCallback: PropTypes.func, // callback to un/select task
        approveCallback: PropTypes.func, // callback to approve task
        rejectCallback: PropTypes.func, // callback to reject task
        reviewCallback: PropTypes.func, // callback to review task
        selected: PropTypes.bool, // if the task is selected
        translate: PropTypes.func, // to translate texts
        displayedDate: PropTypes.string // name of the date to be displayed
    };

    constructor(props) {
        super(props);
        this.state = {
            lastComment: ''
        }
        this.onSelect = this.onSelect.bind(this);
        this.normalizeAmount = this.normalizeAmount.bind(this);
        this.formatTaskDescription = this.formatTaskDescription.bind(this);
        this.getLastComment = this.getLastComment.bind(this);
        this.handleSelectKeyDown = this.handleSelectKeyDown.bind(this);
    }

    /**
     * hide differences in task detail depending on task type :/
     */
    normalizeAmount(task) {
        let unit, amount, foreignUnit, foreignAmount;

        if (task.documentType === DOCUMENT_TYPE.LEAVE_REQUEST) {
            amount = task.numberOfDays;
            unit = this.props.translate('myTasks.task.amountInDays');
        } else if (task.documentType === DOCUMENT_TYPE.TIMESHEET) {
            amount = task.amount;
            unit = this.props.translate('myTasks.task.amountInHours');
            foreignAmount = task.foreignAmount;
            foreignUnit = unit;
        } else {
            amount = task.amount;
            unit = task.currency;
            foreignAmount = task.foreignAmount;
            foreignUnit = task.foreignCurrency;
        }


        let status = shouldShowForeignAmount(amount, unit, foreignAmount, foreignUnit);

        return {
            amount: formatAmountValue(status.amount),
            unit: status.unit,
            foreignAmount: status.foreignAmount ? formatAmountValue(status.foreignAmount) : null,
            foreignCurrency: status.foreignUnit
        }
    }

    formatTaskDescription(task) {
        let description = {
            text: task.description,
            hoverText: task.description,
        };
        if (task.documentType === DOCUMENT_TYPE.LEAVE_REQUEST || task.documentType === DOCUMENT_TYPE.TIMESHEET) {
            let from = new Date(task.fromDate);
            let to = new Date(task.toDate);
            let descriptionDate = undefined;
            if (from.getYear() === to.getYear()) {
                if (from.getMonth() === to.getMonth()) {
                    //(May 1. - 31.) + description
                    descriptionDate = moment(from).format("MMM D.") + " - " + moment(to).format("D.");
                } else {
                    //(Apr 1. - May 2.) + description
                    descriptionDate = moment(from).format("MMM D.") + " - " + moment(to).format("MMM D.");
                }
            } else {
                //(2016 Apr 1. - 2017 May 2.) + description
                descriptionDate = moment(from).format("YYYY MMM D.") + " - " + moment(to).format("YYYY MMM D.");
            }
            description.text = (<span> {"(" + descriptionDate + ") " + task.description}</span>);
        }
        return description;
    }

    getLastComment() {
        Api.getLastComment(this.props.task.key).then(response => {
            if (response && response.content) {
                this.setState({
                    lastComment: response.userName + "\n" + response.content
                });
            }
        })
    }

    onSelect(e) {
        e.stopPropagation();
        e.preventDefault();
        this.props.selectCallback(this.props.task.key);
    };

    handleSelectKeyDown(event) {
        if (event.keyCode === KEYS.ENTER) {
            this.props.selectCallback(this.props.task.key);
        }
    }

    render() {

        let task = this.props.task;
        let typeClass = formatDocumentType(task.documentType, task.amount);
        let amountFields = this.normalizeAmount(task);

        let status = getTaskOverdueStatus(task.documentDueDate, task.dueDateWarning);
        let warningTooltip = status.overdueClass !== TASK_OVERDUE_STATUS.TASK_NOT_OVERDUE ?
            this.props.translate('myTasks.task.taskOverdue', status.dueDate, formatDate(task.dueDate)) : '';

        let getOriginalAssignee = task.originalAssignee ? (this.props.translate("myTasks.task.substitutingFor") + " " + task.originalAssignee) : '';

        let taskClass = "col-md-12 container list-group-item  banner-list " + status.overdueClass + (this.props.selected ? ' selected-row' : '') + (task.isNew ? ' new-task' : '');
        if (task.isHandled) {
            taskClass += " task-handled";
        }

        let fromField = task.supplierName ? task.supplierName : task.requesterName || (task.requesterFirstName ? task.requesterFirstName + " " + task.requesterLastName : '');
        let postponed = task.postponedComment ? this.props.translate("myTasks.task.postponed") : "";
        let taskDescription = this.formatTaskDescription(task);

        let emptyOnChange = () => {
            // we don't need specific onChange, onClick from the surrounding div will catch click on the checkbox too
            // but not specifying will cause react warning about controlled component without onChange
        };

        const reviewRow = this.props.reviewCallback !== undefined;

        const showQuickApprove = task.taskActions?.includes("quick-approve");
        const showQuickReject = task.taskActions?.includes("quick-reject");

        return (
            <li key={task.id} className={taskClass} data-cy="mytasks.task">
                <Link to={TASK_DETAILS_URL_PART + task.key}
                      role="link"
                      className="ast_task_link task-nav-link text-decoration-none row w-100 float-left mx-0 px-0 d-flex flex-row justify-content-between">

                    <div className={"task-checkbox float-left ml-0" + (task.isHandled ? " pe-none" : " ")}
                         title={this.props.translate("myTasks.task.select")}
                         onClick={this.onSelect} onKeyDown={this.handleSelectKeyDown}>

                        <div className={"checkbox form-check m-0 p-0"}>
                            <input type="checkbox" id={"select-task-" + task.id} checked={this.props.selected}
                                   aria-label={this.props.translate("myTasks.task.selectTaskOf", DOCUMENT_TYPE.asString(task.documentType), fromField) + amountFields.amount + amountFields.unit}
                                   onChange={emptyOnChange} disabled={task.isHandled}
                                   data-cy="myTasks.task.select"/>
                            <label className="checkbox-label transparent-text">.</label>
                        </div>

                    </div>
                    <div className="document-type-column text-center px-0">
                            <span
                                title={this.props.translate("documentType." + DOCUMENT_TYPE.asString(task.documentType))}
                                className={typeClass}
                                data-cy="myTasks.task.type"/>
                    </div>

                    <div className="supplier-name-column text-start px-0">
                        <div className="requested font-16 wrap-multiple-lines" data-cy="myTasks.task.supplierName">{fromField}</div>
                        <span
                            className="hidden-default">{this.props.translate('myTasks.column.supplierName')}</span>
                    </div>
                    <div
                        className="text-start ps-0 pe-3 description-column">
                        <div className="description wrap-multiple-lines"
                             title={taskDescription.hoverText} data-cy="myTasks.task.description">{taskDescription.text}</div>
                        <span
                            className="hidden-default">{this.props.translate('myTasks.column.description')}</span>
                    </div>
                    <div className="company-name-column text-start px-0">
                        <div className={`companyName ${task.branch ? 'two-rows mb-2' : ''}`} data-cy="myTasks.task.companyName">{task.companyName}</div>
                        <span className="hidden-default">{this.props.translate('myTasks.column.companyName')}</span>
                        {task.branch ?
                            <span className="branch-name" data-tip data-for={"branchName" + task.id}>
                                    <span className="text-disabled">{task.branch.name}</span>
                                    <Tooltip
                                        id={"branchName" + task.id}
                                        content={() => <div className="text-center">{task.branch.name}</div>}
                                    />
                                </span> : null
                        }

                    </div>
                    <div title={warningTooltip} className="row document-due-column text-start px-0 mx-0">
                            <span className="col-md-12 dueDate px-0" data-cy="myTasks.task.documentDueDate">{formatDate(task[this.props.displayedDate])}
                                {status.overdueClass === TASK_OVERDUE_STATUS.TASK_OVERDUE &&
                                    <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-error ms-2"
                                          alt={warningTooltip}/>
                                }
                                {status.overdueClass === TASK_OVERDUE_STATUS.TASK_SOON_OVERDUE &&
                                    <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-warning ms-2"
                                          alt={warningTooltip}/>
                                }
                            </span>
                        {task.postponedComment &&
                            <span className="col-md-12 px-0">{postponed}</span>
                        }
                        <span
                            className="hidden-default">{this.props.translate('myTasks.column.documentDueDate')}</span>
                    </div>
                    <div className="amount-column text-end px-0">
                            <span className="text-start px-0">
                                <span
                                    className={"font-16 amount " + (task.amount < 0 ? "text-danger" : "")}
                                    data-cy="myTasks.task.amount">
                                    {amountFields.amount}
                                    </span>
                                 <span className="text-start px-0 currency font-16"
                                       data-cy="myTasks.task.currency">
                                     {amountFields.unit}
                                     </span>
                            </span>
                        {amountFields.foreignAmount &&
                            <span><br/>
                                <span className="text-disabled foreign-amount">{amountFields.foreignAmount}</span>
                                <span
                                    className="text-disabled foreign-currency currency text-start">{amountFields.foreignCurrency}</span>
                            </span>
                        }

                        <span className="hidden-default">{this.props.translate('myTasks.column.amount')}</span>
                    </div>

                    <div className="comments-column text-center"
                         title={this.state.lastComment}
                         onMouseEnter={this.getLastComment}>
                            <span
                                className="badge badge-success comments-number" data-cy="myTasks.task.comment">{task.numberOfComments > 0 ? task.numberOfComments : ''}</span>
                        <span className="hidden-default">{this.props.translate("myTasks.task.comment")}</span>

                    </div>


                    {task.isHandled ?
                        <div className="task-actions float-right pt-3 ps-4">
                            <div className="w-100 mt-3 ps-4 text-start">
                                <span className="handled-spinner spinner spinner-sm spinner-default-blue"/>
                                <span
                                    data-tip=''
                                    data-for={"task-handled-tool-" + task.id}
                                    className="handled-info ms-3 vismaicon vismaicon-dynamic vismaicon-info"/>
                                <Tooltip
                                    id={"task-handled-tool-" + task.id}
                                    content={() => {
                                        return <span
                                            className={"d-block"}>{this.props.translate("myTasks.taskWasHandled")}</span>
                                    }}/>
                            </div>
                        </div> :

                        <div className="task-actions d-flex justify-content-end align-items-center flex-wrap px-0">
                            <div>
                                {task.actorType === 'SUBSTITUTE' &&
                                    <div className="ms-2 ms-xl-3"
                                         title={getOriginalAssignee}>
                                        <img src={substitute} alt="substitute"/>
                                    </div>}

                                {!reviewRow && task.taskAssignmentType === 'REVIEW' &&
                                    <div className="ms-2 ms-xl-3"
                                         title={this.props.translate('myTasks.taskIcon.review')}>
                                        <img src={review} alt="review"/>
                                    </div>}

                                {task.taskAssignmentType === 'FORWARDED' &&
                                    <div className="ms-2 ms-xl-3"
                                         title={this.props.translate('myTasks.taskIcon.forwarded')}>
                                        <img src={forwarded} alt="forwarded"/>
                                    </div>}

                                {task.taskAssignmentType === 'REASSIGNED' &&
                                    <div className="ms-2 ms-xl-3"
                                         title={this.props.translate('myTasks.taskIcon.reassigned')}>
                                        <img src={reassigned} alt="reassigned"/>
                                    </div>}
                            </div>
                            <div>
                                {!reviewRow && showQuickApprove &&
                                    <TaskRowAction title={this.props.translate('myTasks.task.approve')}
                                                   callback={this.props.approveCallback}
                                                   parentClass="cursor-pointer ms-2 ms-xl-3"
                                                   className="vismaicon-ok-circle vismaicon-dynamic"/>

                                }
                                {!reviewRow && showQuickReject &&
                                    <TaskRowAction title={this.props.translate('myTasks.task.reject')}
                                                   callback={this.props.rejectCallback}
                                                   parentClass="cursor-pointer ms-2 ms-xl-3"
                                                   className="vismaicon-cancel-circle vismaicon-dynamic"/>
                                }

                                {!reviewRow && !showQuickApprove &&
                                    <div className="cursor-pointer ms-2 ms-xl-3">
                                        <Link to={TASK_DETAILS_URL_PART + task.key} className="ast_task_link">
                                            <img src={viewIcon} alt="view"/>
                                        </Link>
                                    </div>

                                }
                            </div>

                            {reviewRow &&
                                <TaskRowAction title={this.props.translate("myTasks.actionBar.review")}
                                               callback={this.props.reviewCallback}
                                               parentClass="cursor-pointer ms-2 ms-xl-3"
                                               src={review} alt={"review"}/>
                            }

                        </div>
                    }

                </Link>
            </li>
        )
    }
}


/**
 *
 * @param props.title translated title
 * @param props.callback optional
 * @param props.className optional
 * @param props.src optional
 * @return {XML}
 * @constructor
 */
const TaskRowAction = function (props) {

    const withCallback = props.callback !== undefined;
    const divProps = {
        className: props.parentClass,
        title: props.title
    };
    if (withCallback) {
        divProps.onClick = (e) => {
            e.preventDefault();
            props.callback()
        };
        divProps.onKeyDown = (e) => {
            if (e.keyCode === KEYS.ENTER) {
                e.preventDefault();
                props.callback();
            }
        };
    }

    return (
        <div {...divProps} tabIndex={0} role="button" data-cy={"task.action." + divProps.title}>
            {props.className &&
                <span className={props.className + (withCallback ? '' : ' disabled') + " vismaicon"}/>
            }
            {props.src &&
                <img src={props.src} alt={props.alt}/>
            }
        </div>
    );
};


// we need this syntax to make the common.js import working from route definitions (common.js import is mandatory inside bundle split)
export default TaskView;

