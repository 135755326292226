import React, {Component, Fragment} from 'react';
import './PopUp.scss';
import * as Api from '../../utils/api/api.js';
import * as _ from 'lodash';

const TAB_INDEX = 1000;

/**
 * this is simple parent class, which just adds styling for popup content and support for closing on ESC key
 * the reasons for this are:
 * - each popup serves only one purpose, so it's function should be wrapped inside the popup (so popup itself can react on the result of action, especially it's failure)
 * - since each popup has it's own purpose, it's validation is also specific and should be in the concrete popup (so popup itself can react on the validation result by highlighting some fields, for example)
 * - since each popup has it's own purpose, it's action buttons (last row) are also specific and should be handled in the concrete popup (so popup itself can add/remove them as it needs)
 * - each popup has it's parent/owner, which controls it's state, so parent has to provide callback which popup will use to close itself (and possibly pass some values back to parent in case of 'popup pickers')
 * -- displaying the popup is equal to mounting the component
 * -- closing the popup is equal to unmounting the component
 * - code closed for modification, but opened for extension (less to worry with continuous delivery)
 *
 * so children should
 * - implement #buildContent instead of #render
 * - implement #getType to provide some static title (or #getTitle for completely in the child's competence)
 * - if they are overriding componentDidMount, they should call the version from parent too to enable support for close on ESC
 */
export class PopUp extends Component {

    constructor(props) {
        super(props);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.getTitle = this.getTitle.bind(this);

        this.closeButtonRef = React.createRef();
    }

    handleKeyPress(e) {
        if (e.keyCode === 27 && this.props.closeCallback && typeof this.props.closeCallback === "function") {
            this.props.closeCallback();
        }
    }

    componentDidMount() {
        let self = this;
        document.addEventListener('keydown', this.handleKeyPress);
        //only for one task action and if skipComments!==true
        if (this.props.tasks && this.props.tasks.length === 1 && !this.props.skipComments) {
            Api.getTaskComments(this.props.tasks[0].key).then(function (response) {
                let currentFound = _.find(response, ['current', true]);
                if (currentFound)
                    self.setState({comment: currentFound.content});
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }


    buildContent() {
        // to be overriden by children
    }

    getType() {
        // to be overriden by children
    }

    getClasses() {
        // to be overriden by children
        return "";
    }

    getModalClasses() {
        // to be overriden by children
        return "";
    }

    getHeader() {
        return <span/>;
    }

    /**
     * @return title for the popup
     */
    getTitle() {
        if (this.props.noTitle)
            return "";
        return this.props.title || this.props.translate("popUp." + this.getType() + ".title");
    }

    render() {
        let content = this.buildContent();
        let header = this.getHeader();
        return (
            <div>
                {this.props.isCurrentTaskHandled &&
                    <div className={"toast toast-info task-handled-toast position-absolute mx-auto"}>
                        {this.props.translate("popUp.taskHandled")}<a href=""
                                                                      onClick={this.props.closeCallback}>{this.props.translate("popUp.reload")}</a>
                        <button className={"close"} onClick={this.props.closeCallback}></button>
                    </div>
                }
                <div className={this.getClasses() + " pop-up clear ast_modal_" + this.getType()}
                     role="dialog">
                    <div className={this.getModalClasses() + " pop-up-modal modal-dialog"} id={"dialogModal"}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type={"button"} className={"close :focus-visible"} data-toggle="modal"
                                        tabIndex={TAB_INDEX + 1}
                                        data-target="#dialogModal" aria-label="Close"
                                        ref={this.closeButtonRef}
                                        onClick={this.props.closeCallback ? this.props.closeCallback : undefined}/>
                                <h4 className="modal-title">{this.getTitle()}
                                    {header}
                                </h4>
                            </div>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const VisibleToEverybody = ({translate}) => {
    return <p className="text-disabled my-0">{translate("popUp.commentVisibleToEverybody")}</p>;
};


export default PopUp;
